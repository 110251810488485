import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  ContentConfig,
  ContentConfigSearchParams,
  ContentConfigTemplate,
  ContentConfigTemplateSearchParams,
  ContentConfigTemplateSettings,
  ContentConfigTemplateType,
  ContentSettingHint,
  ContentSettingHintType,
  ContentSettingsByHintType,
  ContentTemplateMeta,
  ContentWithSmartContentVariation,
  EmailTestOptions,
  MediaWithType,
  PagedResult,
  PostcardPreview,
  PostcardPreviewOptions
} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";
import {cacheResponse} from "../../utils";

@Injectable({
  providedIn: "root"
})
export class ContentApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/content`;
  }

  searchContent(params: ContentConfigSearchParams): Observable<PagedResult<ContentConfig<ContentConfigTemplateType>>> {
    return this.http.get<PagedResult<ContentConfig<ContentConfigTemplateType>>>(`${this.baseUrl}/`, {
      params: params as unknown as HttpParams
    });
  }

  getContent<T extends ContentConfigTemplateType>(contentId: string): Observable<ContentConfig<T>> {
    return this.http.get<ContentConfig<T>>(`${this.baseUrl}/${contentId}`);
  }

  getContentWithSmartContentVariation<T extends ContentConfigTemplateType>(
    contentId: string
  ): Observable<ContentWithSmartContentVariation<T>> {
    return this.http.get<ContentWithSmartContentVariation<T>>(`${this.baseUrl}/${contentId}/with-smart-content-variation/`);
  }

  saveDraft<T extends ContentConfigTemplateType>(contentId: string, draftSettings: ContentConfigTemplateSettings<T>): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/${contentId}/draft-settings`, {
      draftSettings
    });
  }

  updateTitle(contentId: string, title: string): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/${contentId}/title`, {
      title
    });
  }

  discardDraftChanges(contentId: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${contentId}/discard-draft`, {});
  }

  publishContent(contentId: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${contentId}/publish`, {});
  }

  getPostcardPreview(contentId: string, options: PostcardPreviewOptions): Observable<PostcardPreview> {
    return this.http.post<PostcardPreview>(`${this.baseUrl}/${contentId}/postcard-preview`, {options});
  }

  downloadPostcardProof(contentId: string, options: PostcardPreviewOptions): Observable<{url: string}> {
    return this.http.post<{url: string}>(`${this.baseUrl}/${contentId}/download-postcard-proof`, {options});
  }

  getDefaultBackgroundImages(): Observable<{images: MediaWithType[]}> {
    return this.http.get<{images: MediaWithType[]}>(`${this.baseUrl}/postcard/background-images`, {});
  }

  getContentConfigTemplate<T extends ContentConfigTemplateType>(templateType: T): Observable<ContentConfigTemplate<T>> {
    return this.http.get<ContentConfigTemplate<T>>(`${this.baseUrl}/template/${templateType}/config`);
  }

  getContentTemplateMeta<T extends ContentConfigTemplateType>(templateType: T): Observable<ContentTemplateMeta> {
    return this.http.get<ContentTemplateMeta>(`${this.baseUrl}/content-template-meta/${templateType}`);
  }

  @cacheResponse
  getContentSettingHint<T extends ContentSettingHintType>(settingHintType: T): Observable<ContentSettingsByHintType<T>> {
    return this.http.get<ContentSettingsByHintType<T>>(`${this.baseUrl}/setting-hint/${settingHintType}`);
  }

  @cacheResponse
  getAllContentSettingHint(): Observable<Record<ContentSettingHintType, ContentSettingHint<ContentConfigTemplateType>>> {
    return this.http.get<Record<ContentSettingHintType, ContentSettingHint<ContentConfigTemplateType>>>(`${this.baseUrl}/setting-hint/all`);
  }

  sendTestEmail(contentId: string, options: EmailTestOptions): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/${contentId}/send-test-email`, options);
  }

  getAllTemplates(params: ContentConfigTemplateSearchParams): Observable<ContentConfigTemplate[]> {
    return this.http.post<ContentConfigTemplate[]>(`${this.baseUrl}/template/search`, {
      params
    });
  }

  createContent(templateId: string, contentName: string): Observable<ContentConfig<ContentConfigTemplateType>> {
    return this.http.post<ContentConfig<ContentConfigTemplateType>>(`${this.baseUrl}/`, {
      templateId,
      contentName
    });
  }

  cloneContent(contentConfigId: string, contentName: string): Observable<ContentConfig<ContentConfigTemplateType>> {
    return this.http.post<ContentConfig<ContentConfigTemplateType>>(`${this.baseUrl}/${contentConfigId}/clone`, {
      contentName
    });
  }

  archiveContent(contentConfigId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${contentConfigId}/archive`, {});
  }

  unArchiveContent(contentConfigId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${contentConfigId}/un-archive`, {});
  }

  deleteContent(contentConfigId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${contentConfigId}`, {});
  }

  getPendingEmailCount(contentConfigId: string): Observable<{count: number}> {
    return this.http.get<{count: number}>(`${this.baseUrl}/${contentConfigId}/pending-email-count`);
  }
}

import {Component, Inject, InjectionToken, Input, OnInit, PLATFORM_ID, ViewContainerRef} from "@angular/core";
import {CommonModule, isPlatformBrowser} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {BehaviorSubject, combineLatest, of} from "rxjs";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {Color, NgxChartsModule, ScaleType} from "@swimlane/ngx-charts";
import {catchError, takeUntil} from "rxjs/operators";
import {flatMap, orderBy} from "lodash-es";
import moment from "moment/moment";
import {AVMHistoryInfo, DataProviderType, providerTypeLabel} from "fello-model";
import {EnumToLabelPipe, FormatPricePipe} from "../../../../fello-ui-utils";
import {DestroyableBase, FormatNumberShorthandUtil} from "../../../../../lib";
import {AbstractDashboardService} from "../../../services";
import {HomeValueTrendCalculationV2Component, ImproveHomeEstimateComponent} from "../../dialogs";

const ProviderColors: Partial<Record<DataProviderType, string>> = {
  [DataProviderType.CL_BULK]: "#EF4400",
  [DataProviderType.ATTOM_BULK]: "#4C4C4C",
  [DataProviderType.QUANTARIUM]: "#64B8D7"
};

@Component({
  selector: "lib-home-value-graph",
  standalone: true,
  imports: [CommonModule, EnumToLabelPipe, NgxChartsModule, FormatPricePipe, NgxSkeletonLoaderModule],
  templateUrl: "./home-value-graph.component.html",
  styleUrls: ["./home-value-graph.component.scss"]
})
export class HomeValueGraphComponent extends DestroyableBase implements OnInit {
  xAxisTicks: string[] = [];
  yAxisMinValue: number;
  yAxisMaxValue: number;
  historyInfo = {
    value: "",
    name: ""
  };
  avmHis = [
    {
      name: "CoreLogic",
      series: [] as {value: number; name: string; data: AVMHistoryInfo}[]
    },
    {
      name: "ATTOM",
      series: [] as {value: number; name: string; data: AVMHistoryInfo}[]
    }
  ];

  colorScheme: Color = {
    name: "color",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: []
  };
  isLoading = true;
  private _dataProviders$ = new BehaviorSubject<DataProviderType[]>([]);
  isDummyDashboard: boolean | null = true;

  @Input() title = "Home Value Trend";
  @Input() showCTAInDialog: boolean;

  @Input()
  get dataProviders(): DataProviderType[] {
    return this._dataProviders$.value;
  }

  set dataProviders(value: DataProviderType[]) {
    this._dataProviders$.next(value);
  }

  constructor(
    private dashboardService: AbstractDashboardService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: InjectionToken<unknown>,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      combineLatest([this.dashboardService.multiAvmHistory$, this._dataProviders$, this.dashboardService.isDummyDashboard$])
        .pipe(
          takeUntil(this.isDestroyed),
          catchError(() => of([null, null]))
        )
        .subscribe(
          ([avmMultiHistory, providers, isDummy]) => {
            this.isLoading = true;
            this.isDummyDashboard = isDummy;
            this.avmHis = [];
            this.colorScheme.domain = [];
            if (avmMultiHistory && providers) {
              for (const provider of providers) {
                let avmHistory = avmMultiHistory.history.filter(a => a.provider === provider);
                if (!avmHistory.length) {
                  continue;
                }
                this.colorScheme.domain.push(ProviderColors[provider]!);
                avmHistory = orderBy(avmHistory, ["valueDate"], ["asc"]);
                this.xAxisTicks = [];
                this.yAxisMinValue = 0;
                this.yAxisMaxValue = 0;
                this.historyInfo = {
                  value: "",
                  name: ""
                };
                avmHistory = orderBy(avmHistory, ["valueDate"], ["desc"]);
                avmHistory = avmHistory.slice(0, 24); // last 2 year to display on chart
                avmHistory = orderBy(avmHistory, ["valueDate"], ["asc"]);
                const totalPoints = avmHistory.length;
                const considerSixPt = Math.floor(totalPoints / 5); // here we doing 5 pts as 6th pt always be the home value estimate record
                avmHistory
                  .slice()
                  .reverse()
                  .forEach((data, index) => {
                    // apend x axis with only 6 pts
                    if (index % considerSixPt == 0) {
                      this.xAxisTicks.push(moment(data.valueDate).utc().format("MMM YYYY"));
                    }
                  });
                this.xAxisTicks = this.xAxisTicks.reverse();
                const series = avmHistory.map(data => ({
                  value: data.median,
                  name: moment(data.valueDate).utc().format("MMM YYYY"),
                  data
                }));
                this.avmHis.push({
                  name: providerTypeLabel[provider],
                  series
                });
                this.updateMinMaxYaxisValue();
              }
            }
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  updateMinMaxYaxisValue(): void {
    const flattenedValues = flatMap(this.avmHis, his => his.series.map(item => item.value));
    const arrayMin = Math.min(...flattenedValues);
    const arrayMax = Math.max(...flattenedValues);
    this.yAxisMinValue = Math.round((arrayMin - 0.2 * arrayMin) / 1000) * 1000;
    this.yAxisMaxValue = Math.round((arrayMax + 0.1 * arrayMax) / 1000) * 1000;
  }

  formatYaxisValue(number: number) {
    return FormatNumberShorthandUtil.transform(number, "$");
  }

  formatYaxisDummyValue(number: number) {
    return "$XXXK";
  }

  getHomeValueInfo() {
    this.dashboardService.isDummyDashboard$.subscribe(isDummyDashboard => {
      const dialogRef = this.dialog.open(HomeValueTrendCalculationV2Component, {
        viewContainerRef: this.viewContainerRef,
        panelClass: ["mobile-bottom-sheet-2", "bg-slate"],
        data: {
          showCTA: this.showCTAInDialog,
          isCTAClickable: !isDummyDashboard
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.openImproveEstimate();
        }
      });
    });
  }

  openImproveEstimate(): void {
    this.dialog.open(ImproveHomeEstimateComponent, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet"]
    });
  }
}

import {Directive, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output} from "@angular/core";

@Directive({
  selector: "[appDashboardItem]",
  exportAs: "appDashboardItem"
})
export class DashboardItemDirective implements OnInit {
  @Input() title: string;
  @Output() visibilityChanged = new EventEmitter<boolean>();
  @HostBinding("class") class = "d-block";

  #_isVisible: boolean;
  public active = false;

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {
    this.#updateVisible();
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get offsetTop(): number {
    return this.elementRef.nativeElement.offsetTop;
  }

  get isVisible(): boolean {
    this.#updateVisible();
    return this.#_isVisible;
  }

  #updateVisible() {
    if (this.#_isVisible !== !!this.elementRef.nativeElement.offsetHeight) {
      // update the visibility if changed in next change detection cycle
      Promise.resolve().then(() => {
        this.#_isVisible = !!this.elementRef.nativeElement.offsetHeight;
        this.visibilityChanged.emit(this.#_isVisible);
      });
    }
  }
}

<div class="db-card" *ngIf="isLoading">
  <ngx-skeleton-loader count="1" class="mb-2" [theme]="{  height: '55px', width: '100%' }"></ngx-skeleton-loader>
  <ngx-skeleton-loader count="5" class="mb-1" [theme]="{  height: '66px', width: '100%' }"></ngx-skeleton-loader>
</div>
<ng-container *ngIf="!isLoading && (marketTrend$ | async); let marketTrend">
  <div
    class="db-card"
    id="marketCard"
    [ngClass]="{'collapse': !cardOpen}"
    *ngIf="marketTrend.homeSoldInLast30Days.value && marketTrend.newListingInLast30Days.value"
  >
    <div class="flex-box-between db-card-header">
      <div *ngIf="property$  |async ; let property">
        <p class="db-card-title">Market Trends</p>
        <p class="db-card-desc">
          <span class="actual-data">
            {{property?.addressComponents?.city}} , {{property?.addressComponents?.state}} {{property?.addressComponents?.zip}}
          </span>
          <span class="dummy-data">
            <ngx-skeleton-loader
              [animation]="false"
              count="1"
              [theme]="{height: '20px', width: '200px', borderRadius: 0, backgroundColor: 'var(--slate-2)'}"
            ></ngx-skeleton-loader>
          </span>
        </p>
      </div>
      <div class="flex-box-align-start">
        <div class="flex-box-center">
          <span class="tag tag--{{ marketTrend.marketCondition.toLowerCase() }} ml-3 actual-data">{{ marketTrend.marketCondition }}</span>
          <button class="button-icon ml-2 card-collapse-button" [ngClass]="{'button-secondary': !cardOpen}" (click)="cardOpen = !cardOpen">
            <app-icon *ngIf="cardOpen" fontSize="36" class="fw-100">up</app-icon>
            <app-icon *ngIf="!cardOpen" fontSize="36" class="fw-100">down</app-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="db-card-body">
      <div *ngIf="marketTrend.newListingInLast30Days.value!=null">
        <div class="line-break small"></div>
        <div class="flex-box-center-between">
          <p class="text-size-30 fw-700">
            <span class="actual-data">{{marketTrend.newListingInLast30Days.value}}</span>
            <span class="dummy-data">XX</span>
          </p>
          <p class="text-size-16 fw-500 actual-data" *ngIf="marketTrend.newListingInLast30Days.change!=null">
            {{marketTrend.newListingInLast30Days.change | number:'1.0-0'}}%
          </p>
          <span class="dummy-data">
            <ngx-skeleton-loader
              [animation]="false"
              count="1"
              [theme]="{height: '25px', width: '60px', borderRadius: 0, backgroundColor: 'var(--slate-2)'}"
            ></ngx-skeleton-loader>
          </span>
        </div>
        <p>New Listings in the Last 30 days</p>
      </div>

      <div *ngIf="marketTrend.homeSoldInLast30Days.value!=null">
        <div class="line-break small"></div>
        <div class="flex-box-center-between">
          <p class="text-size-30 fw-700">
            <span class="actual-data">{{marketTrend.homeSoldInLast30Days.value}}</span>
            <span class="dummy-data">XX</span>
          </p>
          <p class="text-size-16 fw-500 actual-data" *ngIf="marketTrend.homeSoldInLast30Days.change!=null">
            {{marketTrend.homeSoldInLast30Days.change | number:'1.0-0'}}%
          </p>
          <span class="dummy-data">
            <ngx-skeleton-loader
              [animation]="false"
              count="1"
              [theme]="{height: '25px', width: '60px', borderRadius: 0, backgroundColor: 'var(--slate-2)'}"
            ></ngx-skeleton-loader>
          </span>
        </div>
        <p>Homes Sold in the Last 30 days</p>
      </div>

      <div *ngIf="marketTrend.medianPrice.value!=null">
        <div class="line-break small"></div>
        <div class="flex-box-center-between">
          <p class="text-size-30 fw-700">
            <span class="actual-data">{{marketTrend.medianPrice.value | currency :'USD' : 'symbol' : '1.0-0' }}</span>
            <span class="dummy-data">$XXX,XXX</span>
          </p>
          <p class="text-size-16 fw-500 actual-data" *ngIf="marketTrend.medianPrice.change!=null">
            {{marketTrend.medianPrice.change | number:'1.0-0'}}%
          </p>
          <span class="dummy-data">
            <ngx-skeleton-loader
              [animation]="false"
              count="1"
              [theme]="{height: '25px', width: '60px', borderRadius: 0, backgroundColor: 'var(--slate-2)'}"
            ></ngx-skeleton-loader>
          </span>
        </div>
        <p>Median Sale Price</p>
      </div>

      <div *ngIf="marketTrend.daysOnMarket.value!=null">
        <div class="line-break small"></div>
        <div class="flex-box-center-between">
          <p class="text-size-30 fw-700">
            <span class="actual-data">{{marketTrend.daysOnMarket.value}}</span>
            <span class="dummy-data">XX</span>
          </p>
          <p class="text-size-16 fw-500 actual-data" *ngIf="marketTrend.daysOnMarket.change!=null">
            {{marketTrend.daysOnMarket.change | number:'1.0-0'}}%
          </p>
          <span class="dummy-data">
            <ngx-skeleton-loader
              [animation]="false"
              count="1"
              [theme]="{height: '25px', width: '60px', borderRadius: 0, backgroundColor: 'var(--slate-2)'}"
            ></ngx-skeleton-loader>
          </span>
        </div>
        <p>Days on Market</p>
      </div>
      <div *ngIf="marketTrend.averagePriceChange.value!=null">
        <div class="line-break small"></div>
        <div class="flex-box-center-between">
          <p class="text-size-30 fw-700">
            <span class="actual-data">{{marketTrend.averagePriceChange.value | currency: 'USD' : 'symbol' : '1.0-0'}}</span>
            <span class="dummy-data">$XXX</span>
          </p>
          <p class="text-size-16 fw-500 actual-data" *ngIf="marketTrend.averagePriceChange.change!=null">
            {{marketTrend.averagePriceChange.change | number:'1.0-0'}}%
          </p>
          <span class="dummy-data">
            <ngx-skeleton-loader
              [animation]="false"
              count="1"
              [theme]="{height: '25px', width: '60px', borderRadius: 0, backgroundColor: 'var(--slate-2)'}"
            ></ngx-skeleton-loader>
          </span>
        </div>
        <p>Average Price Change</p>
      </div>

      <ng-container *ngIf="moduleSetting.showCTA">
        <div class="line-break"></div>
        <button class="link text-center w-100 fw-500 text-size-14" (click)="openContactAgent()">Need more insights? Send a message</button>
      </ng-container>
    </div>
  </div>
</ng-container>

export enum AgentContactSystemTag {
  CALLBACK_REQUEST = "CALLBACK_REQUEST",
  CMA_REQUEST = "CMA_REQUEST",
  PENDING_SELLER_INFO = "PENDING_SELLER_INFO",
  CASH_OFFER = "CASH_OFFER",
  ENRICH = "ENRICH",
  NEW = "NEW",
  REFERRAL = "REFERRAL",
  POTENTIAL_SELLER = "POTENTIAL_SELLER",
  HOME_VALUE = "HOME_VALUE",
  SELLER_LEAD = "SELLER_LEAD",
  MORTGAGE_LEAD = "MORTGAGE_LEAD",
  AFFORDABILITY_LEAD = "AFFORDABILITY_LEAD",

  FELLO_CALLBACK_REQUEST = "FELLO_CALLBACK_REQUEST",
  FELLO_CMA_REQUEST = "FELLO_CMA_REQUEST",
  FELLO_CASH_OFFER = "FELLO_CASH_OFFER",
  FELLO_HOME_VALUE = "FELLO_HOME_VALUE",
  FELLO_SELLER_LEAD = "FELLO_SELLER_LEAD",
  FELLO_MORTGAGE_LEAD = "FELLO_MORTGAGE_LEAD",
  FELLO_AFFORDABILITY_LEAD = "FELLO_AFFORDABILITY_LEAD",

  // Homeward
  HOMEWARD = "HOMEWARD",
  HOMEWARD_PRELIM_OFFER = "HOMEWARD_PRELIM_OFFER"
}

export const AgentContactSystemTagToLabelMapping: Record<string, string | undefined> = {
  [AgentContactSystemTag.CALLBACK_REQUEST]: "Callback Request",
  [AgentContactSystemTag.CMA_REQUEST]: "CMA Request",
  [AgentContactSystemTag.PENDING_SELLER_INFO]: "Pending Home Details",
  [AgentContactSystemTag.CASH_OFFER]: "Cash Offer",
  [AgentContactSystemTag.ENRICH]: "Enrich",
  [AgentContactSystemTag.NEW]: "New",
  [AgentContactSystemTag.REFERRAL]: "Referral",
  [AgentContactSystemTag.POTENTIAL_SELLER]: "Potential Seller",
  [AgentContactSystemTag.HOME_VALUE]: "Home Value",
  [AgentContactSystemTag.SELLER_LEAD]: "Seller Lead",
  [AgentContactSystemTag.MORTGAGE_LEAD]: "Mortgage Lead",
  [AgentContactSystemTag.AFFORDABILITY_LEAD]: "Affordability Lead",

  [AgentContactSystemTag.FELLO_CALLBACK_REQUEST]: "Fello Callback Lead",
  [AgentContactSystemTag.FELLO_CMA_REQUEST]: "Fello CMA Lead",
  [AgentContactSystemTag.FELLO_CASH_OFFER]: "Fello Cash Offer Lead",
  [AgentContactSystemTag.FELLO_HOME_VALUE]: "Fello Home Value Lead",
  [AgentContactSystemTag.FELLO_SELLER_LEAD]: "Fello Seller Lead",
  [AgentContactSystemTag.FELLO_MORTGAGE_LEAD]: "Fello Mortgage Lead",
  [AgentContactSystemTag.FELLO_AFFORDABILITY_LEAD]: "Fello Affordability Lead",
  [AgentContactSystemTag.HOMEWARD]: "Homeward",
  [AgentContactSystemTag.HOMEWARD_PRELIM_OFFER]: "Homeward Prelim Offer"
};

export const AgentContactSystemTagToColorMapping: Record<string, string> = {
  [AgentContactSystemTag.NEW]: "blue-light",
  [AgentContactSystemTag.CALLBACK_REQUEST]: "slate-1",
  [AgentContactSystemTag.CMA_REQUEST]: "slate-1",
  [AgentContactSystemTag.PENDING_SELLER_INFO]: "slate-1",
  [AgentContactSystemTag.CASH_OFFER]: "slate-1",
  [AgentContactSystemTag.SELLER_LEAD]: "slate-1",
  [AgentContactSystemTag.ENRICH]: "slate-1",
  [AgentContactSystemTag.REFERRAL]: "slate-1",
  [AgentContactSystemTag.POTENTIAL_SELLER]: "slate-1",
  [AgentContactSystemTag.HOME_VALUE]: "slate-1",
  [AgentContactSystemTag.MORTGAGE_LEAD]: "slate-1",
  [AgentContactSystemTag.AFFORDABILITY_LEAD]: "slate-1",

  [AgentContactSystemTag.FELLO_CALLBACK_REQUEST]: "slate-1",
  [AgentContactSystemTag.FELLO_CMA_REQUEST]: "slate-1",
  [AgentContactSystemTag.FELLO_CASH_OFFER]: "slate-1",
  [AgentContactSystemTag.FELLO_SELLER_LEAD]: "slate-1",
  [AgentContactSystemTag.FELLO_HOME_VALUE]: "slate-1",
  [AgentContactSystemTag.FELLO_MORTGAGE_LEAD]: "slate-1",
  [AgentContactSystemTag.FELLO_AFFORDABILITY_LEAD]: "slate-1",
  [AgentContactSystemTag.HOMEWARD]: "slate-1",
  [AgentContactSystemTag.HOMEWARD_PRELIM_OFFER]: "slate-1"
};

import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewContainerRef} from "@angular/core";
import {mixinDestroyable} from "../../../../lib/mixins/destroy";
import {shareReplay, take, takeUntil, tap} from "rxjs/operators";
import {ContactAgentComponent} from "../dialogs";
import {DashboardModuleType, FelloUserContactDetails} from "fello-model";
import {MatDialog} from "@angular/material/dialog";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {AbstractDashboardService} from "../../services";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: "app-market-trends",
  templateUrl: "./market-trends.component.html",
  styleUrls: ["./market-trends.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs
})
export class MarketTrendsComponent
  extends mixinDestroyable(class extends DashboardModuleDirective<DashboardModuleType.MARKET_TRENDS> {})
  implements OnInit
{
  isLoading = true;
  cardOpen = true;
  marketTrend$ = this.dashboardService.getMarketStatistics().pipe(takeUntil(this.isDestroyed), shareReplay(1));
  property$ = this.dashboardService.property$;

  constructor(
    private dashboardService: AbstractDashboardService,
    private viewContainerRef: ViewContainerRef,
    private dialog: MatDialog,
    elementRef: ElementRef,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super(elementRef);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.dashboardService.property$
        .pipe(
          takeUntil(this.isDestroyed),
          tap(() => (this.isLoading = true))
        )
        .subscribe();
      this.marketTrend$.pipe(takeUntil(this.isDestroyed)).subscribe(
        () => (this.isLoading = false),
        () => (this.isLoading = false)
      );
    }
  }

  openContactAgent() {
    this.dashboardService.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.dialog.open(ContactAgentComponent, {
            maxWidth: 580,
            data: {
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.emailId
            } as FelloUserContactDetails,
            viewContainerRef: this.viewContainerRef
          });
        })
      )
      .subscribe();
  }
}

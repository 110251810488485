import {Directive, Input} from "@angular/core";
import {AvailableDashboardModules, DashboardModuleSetting} from "fello-model";
import {DashboardItemDirective} from "./dashboard-item.directive";

@Directive({
  selector: "[libDashboardModule]"
})
export class DashboardModuleDirective<ModuleType extends AvailableDashboardModules> extends DashboardItemDirective {
  #_moduleSetting: DashboardModuleSetting<ModuleType>;

  @Input()
  moduleId: string;

  @Input()
  get moduleSetting(): DashboardModuleSetting<ModuleType> {
    return this.#_moduleSetting;
  }

  set moduleSetting(value: DashboardModuleSetting<ModuleType>) {
    this.#_moduleSetting = value;
    this.title = this.#_moduleSetting.name;
  }
}

export const DashboardModuleDirectiveInputs = ["moduleSetting"];

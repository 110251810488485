import {Component, forwardRef, Input} from "@angular/core";
import {BehaviorSubject, combineLatest} from "rxjs";
import {map} from "rxjs/operators";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {IconComponent} from "../../../fello-ui-utils";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";

export interface DropdownOption {
  value: any;
  label: string;
}

@Component({
  selector: "lib-dropdown-with-search",
  templateUrl: "./dropdown-with-search.component.html",
  styleUrls: ["./dropdown-with-search.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownWithSearchComponent),
      multi: true
    }
  ],
  imports: [MatSelectModule, IconComponent, FormsModule, NgForOf, NgIf, AsyncPipe],
  standalone: true
})
export class DropdownWithSearchComponent implements ControlValueAccessor {
  search$ = new BehaviorSubject<string>("");
  private options$ = new BehaviorSubject<DropdownOption[]>([]);
  filteredOptions$ = combineLatest([this.options$, this.search$]).pipe(
    map(([options, search]) => {
      if (!search) {
        return options;
      }
      search = search.toLowerCase();
      return options.filter(option => {
        return option.label.toLowerCase().includes(search);
      });
    })
  );

  @Input()
  get options(): DropdownOption[] {
    return this.options$.getValue();
  }

  set options(value: DropdownOption[]) {
    this.options$.next(value);
  }

  @Input() placeholder: string;
  @Input() disableOptionCentering = false;
  @Input() panelClass: string | string[] | Set<string> | {[p: string]: any};
  @Input() compareWith: (o1: any, o2: any) => boolean = (o1, o2) => o1 === o2;

  value: any;
  disabled: boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, of, ReplaySubject, switchMap} from "rxjs";
import {AgentContactSystemTagToLabelMapping, FieldFilterAllowedValue, FieldFilterDynamicListType, FQLFieldOpr} from "fello-model";
import {delay, map, retryWhen} from "rxjs/operators";

export abstract class BaseDynamicList {
  private isListInitialized = false;
  private isLoadingSubject = new BehaviorSubject(false);
  isLoading$ = this.isLoadingSubject.asObservable();

  private allowedValuesSubject = new ReplaySubject<FieldFilterAllowedValue[]>(1);
  allowedValues$ = this.allowedValuesSubject.asObservable();
  private allowedValues: FieldFilterAllowedValue[];

  protected constructor() {
    this.allowedValuesSubject.subscribe(a => (this.allowedValues = a));
    of(null)
      .pipe(
        delay(0),
        switchMap(() => this.getAllowedValues())
      )
      .subscribe();
  }

  private getAllowedValues(): Observable<FieldFilterAllowedValue[]> {
    if (!this.isListInitialized) {
      this.isLoadingSubject.next(true);
      this.getAllListItems()
        .pipe(retryWhen(err => err.pipe(delay(3000))))
        .subscribe(listItems => {
          this.isLoadingSubject.next(false);
          this.allowedValuesSubject.next(listItems);
        });
      this.isListInitialized = true;
    }
    return this.allowedValuesSubject.asObservable();
  }

  getAllowedValuesLabel(opr: FQLFieldOpr[]): string {
    if (!this.allowedValues?.length) {
      return "";
    }
    return this.allowedValues
      .filter(a => opr.includes(a.value))
      .map(a => a.label)
      .join(", ");
  }

  abstract getAllListItems(): Observable<FieldFilterAllowedValue[]>;
}

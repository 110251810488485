import {Component, ElementRef} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
  BaseOptionsModuleOption,
  DashboardModuleButtonType,
  DashboardModuleType,
  SellingOptionType,
  SellingOptionTypeToKeyMap,
  SellingOptionWrapper
} from "fello-model";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib/mixins/";
import {Observable} from "rxjs";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {NgxSpinnerModule, NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormatPricePipe, NumberShorthandPipe} from "../../../fello-ui-utils";
import {AbstractDashboardService} from "../../services";
import {map} from "rxjs/operators";
import {orderBy} from "lodash-es";
import {CustomSellingOptionComponent} from "./custom-selling-option/custom-selling-option.component";
import {SellingOptionHostDirective} from "./directives/selling-option-host.directive";
import {HomewardSellingOptionComponent} from "./homeward-selling-option/homeward-selling-option.component";

const SellingOptionsBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.SELLING_OPTIONS> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-selling-options",
  standalone: true,
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    NumberShorthandPipe,
    FormatPricePipe,
    CustomSellingOptionComponent,
    HomewardSellingOptionComponent,
    SellingOptionHostDirective,
    NgxSpinnerModule
  ],
  templateUrl: "./selling-options.component.html",
  styleUrls: ["./selling-options.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs
})
export class SellingOptionsComponent extends SellingOptionsBaseComponent {
  protected readonly DashboardModuleButtonType = DashboardModuleButtonType;

  sellingOptionsResponse$ = this.dashboardService.sellingOptionsResponse$;
  sellingOptionWrappers$: Observable<SellingOptionWrapper<SellingOptionType>[]> = this.sellingOptionsResponse$.pipe(
    map(sellingOptionsResponse => {
      return orderBy(
        [sellingOptionsResponse.customSellingOption1, sellingOptionsResponse.customSellingOption2, sellingOptionsResponse.homeward].filter(
          option => this.isSellingOptionEnabled(option)
        ),
        (option: SellingOptionWrapper<SellingOptionType>) => this.getSellingOptionModuleSetting(option.sellingOptionType).seq
      ) as SellingOptionWrapper<SellingOptionType>[];
    })
  );

  constructor(
    private dashboardService: AbstractDashboardService,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar,
    elementRef: ElementRef
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  isSellingOptionEnabled<T extends SellingOptionType>(option: SellingOptionWrapper<T> | undefined): option is SellingOptionWrapper<T> {
    return !!option && this.getSellingOptionModuleSetting(option.sellingOptionType).enabled;
  }

  getSellingOptionModuleSetting(sellingOptionType: SellingOptionType): BaseOptionsModuleOption {
    return this.moduleSetting[SellingOptionTypeToKeyMap[sellingOptionType]] as BaseOptionsModuleOption;
  }
}

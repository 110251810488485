import {Component, ElementRef, OnInit, ViewContainerRef} from "@angular/core";
import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives";
import {DashboardModuleButtonAction, DashboardModuleButtonType, DashboardModuleType} from "fello-model";
import {AbstractDashboardService} from "../../services";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil, tap} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";

const LenderBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.LENDER> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-lender",
  templateUrl: "./lender.component.html",
  styleUrls: ["./lender.component.scss"],
  inputs: DashboardModuleDirectiveInputs
})
export class LenderComponent extends LenderBaseComponent implements OnInit {
  isLoading = true;
  lender$ = this.dashboardService.lender$;
  DashboardModuleButtonType = DashboardModuleButtonType;

  constructor(
    private dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar,
    elementRef: ElementRef
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  ngOnInit() {
    this.dashboardService.isPropertyLoading
      .pipe(
        takeUntil(this.isDestroyed),
        tap(val => (this.isLoading = val))
      )
      .subscribe(
        () => {},
        () => (this.isLoading = false)
      );
  }

  performCTAAction(action: DashboardModuleButtonAction): void {
    this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
  }
}

<div class="flex-box-center-between mb-4">
  <p class="fw-600 text-size-22">Improve Your Home Estimate</p>
  <button class="button-icon close ml-auto close-button" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>

<mat-dialog-content class="visible-scrollbar">
  <div class="box-c mb-4 bg-blue-00">
    <p class="text-size-14 fw-400">Please verify and edit any information below for accuracy.</p>
  </div>
  <app-home-details-stats
    #homeDetailsStatsComponent
    [homeFacts]="data.basicHomeFacts"
    [ngClass]="{'d-none': currentStep !== 1}"
  ></app-home-details-stats>
  <form #improveEstimateForm="ngForm" [ngClass]="{'d-none': currentStep !== 2}">
    <div class="flex-box flex-box-gap-2">
      <div class="form-field mb-4">
        <label for="form-first-name">First name</label>
        <input
          placeholder="First name"
          name="firstName"
          type="text"
          id="form-first-name"
          #firstNameField="ngModel"
          [maxlength]="49"
          required
          [(ngModel)]="data.firstName"
        />
        <div *ngIf="firstNameField.invalid && firstNameField.touched" class="text-size-12 text-color-red mt-1">
          <div *ngIf="firstNameField.errors!.required">Enter a First Name.</div>
          <div *ngIf="firstNameField.errors!.maxlength">First name cannot have more than 49 characters.</div>
        </div>
      </div>

      <div class="form-field mb-4">
        <label for="form-last-name">Last name</label>
        <input
          placeholder="Last name"
          name="lastName"
          type="text"
          id="form-last-name"
          #lastNameField="ngModel"
          [maxlength]="49"
          required
          [(ngModel)]="data.lastName"
        />
        <div *ngIf="lastNameField.invalid && lastNameField.touched" class="text-size-12 text-color-red mt-1">
          <div *ngIf="lastNameField.errors!.required">Enter a Last Name.</div>
          <div *ngIf="lastNameField.errors!.maxlength">Last name cannot have more than 49 characters.</div>
        </div>
      </div>
    </div>

    <div class="form-field mb-4">
      <label>Phone</label>
      <input
        type="text"
        [(ngModel)]="data.phone"
        #phone="ngModel"
        [pattern]="phoneNumberPattern"
        libFormatPhone
        placeholder="Phone"
        name="phone"
        required
      />
      <div *ngIf="phone.invalid && phone.touched" class="text-size-12 text-color-red mt-1">
        <div *ngIf="phone.errors!">Enter a valid phone.</div>
      </div>
    </div>

    <label>
      Your Home Value
      <span class="fw-400 text-color-light">(optional)</span>
    </label>
    <div class="form-field mb-4 prefix" data-prefix="$">
      <lib-amount-comma-separated
        [(ngModel)]="data.homeEstimate"
        #estimate="ngModel"
        class="text-right"
        placeholder="0"
        name="estimate"
        [libMax]="100000000"
      ></lib-amount-comma-separated>
      <div *ngIf="estimate.invalid && estimate.touched" class="text-size-12 text-color-red mt-1">
        <div *ngIf="estimate.errors!">Enter a valid home estimate.</div>
      </div>
    </div>

    <div class="form-field w-100 mb-4">
      <label>Are you considering selling your home? If yes, what is your potential timeframe? </label>
      <div class="form-field flex-box-center flex-box-gap-2 flex-wrap w-100">
        <div class="flex-box-center">
          <input
            type="radio"
            id="option0"
            class="mr-2 w-auto"
            name="home-buy"
            [value]="SaleTimeline.ASAP"
            [(ngModel)]="data.saleTimeline"
          />
          <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option0">
            {{ SaleTimeline.ASAP| enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}
          </label>
        </div>
        <div class="flex-box-center">
          <input
            type="radio"
            id="option1"
            class="mr-2 w-auto"
            name="home-buy"
            [value]="SaleTimeline.LT3M"
            [(ngModel)]="data.saleTimeline"
          />
          <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option1">
            {{ SaleTimeline.LT3M| enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}</label
          >
        </div>
        <div class="flex-box-center">
          <input
            type="radio"
            id="option2"
            class="mr-2 w-auto"
            name="home-buy"
            [value]="SaleTimeline.B3TO6M"
            [(ngModel)]="data.saleTimeline"
          />
          <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option2">
            {{ SaleTimeline.B3TO6M| enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}
          </label>
        </div>
        <div class="flex-box-center">
          <input
            type="radio"
            id="option3"
            class="mr-2 w-auto"
            name="home-buy"
            [value]="SaleTimeline.B6TO12M"
            [(ngModel)]="data.saleTimeline"
          />
          <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option3">
            {{ SaleTimeline.B6TO12M| enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}
          </label>
        </div>
        <div class="flex-box-center">
          <input type="radio" id="option4" class="mr-2 w-auto" name="home-buy" [value]="SaleTimeline.NA" [(ngModel)]="data.saleTimeline" />
          <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option4"
            >{{ SaleTimeline.NA| enumToLabel:"FELLO_SALE_TIMELINE_SHORT" }}</label
          >
        </div>
      </div>
      <div *ngIf="!data.saleTimeline && showErrors" class="text-size-12 text-color-red mt-1">
        <div>Please select an option.</div>
      </div>
    </div>

    <div class="form-field w-100">
      <label>Message to Agent <span class="fw-400 text-color-light">(optional)</span></label>
      <textarea [(ngModel)]="data.message" name="message" #message [maxlength]="2500"></textarea>
      <div class="textarea-count text-size-10 text-color-light">{{ message.textLength }}/2500</div>
    </div>
    <p class="text-size-12 text-color-light-2" *ngIf="currentStep === 2 && (settings$ | async); let settings">
      <ng-container *ngIf="settings.consentText">
        <span dynamicTooltipDirective class="link-underline" [innerHTML]="settings.consentText | safeHtml"></span>
      </ng-container>
      <ng-container *ngIf="!settings.consentText">
        By Proceeding, you agree to our
        <a target="_blank" [href]="settings.termsAndConditionsUrl ?? felloWebsites.TERMS_AND_CONDITIONS" class="text-underline"
          >Terms of Service</a
        >, and to our
        <a target="_blank" [href]="settings.privacyPolicyUrl ?? felloWebsites.PRIVACY_POLICY" class="text-underline">Privacy Policy</a>
        regarding the information relating to you. Msg/data rates may apply. This consent applies even if you are on a corporate, state or
        national Do Not Call list.
      </ng-container>
    </p>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mt-3 flex-box-direction-column-reverse flex-box-lg-direction-row flex-box-gap-1">
  <button
    class="ml-auto w-sm-100 button-mid"
    [ngClass]="{'d-none': currentStep !== 1}"
    [disabled]="!homeDetailsStatsComponent.isValid"
    (click)="homeDetailsStatsComponent.isValid && (currentStep = 2) && (data.basicHomeFacts = homeDetailsStatsComponent.asBasicHomeFactsUpdateRequest())"
  >
    Continue
  </button>

  <button (click)="currentStep = 1" [ngClass]="{'d-none': currentStep !== 2}" class="button-white button-primary w-sm-100 button-mid">
    Back
  </button>

  <button
    class="ml-auto w-sm-100 button-mid"
    [ngClass]="{'d-none': currentStep !== 2}"
    (click)="improveEstimateForm.control.markAllAsTouched();showErrors=true; (improveEstimateForm.valid && data.saleTimeline) && requestCMA()"
  >
    Submit
  </button>
</mat-dialog-actions>
<ngx-spinner [name]="spinnerName" [fullScreen]="true"></ngx-spinner>

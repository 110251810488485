<div class="db-card equity-card page-break-inside-avoid" [ngClass]="{'collapse': !cardOpen}">
  <div class="flex-box-between db-card-header">
    <div>
      <p class="db-card-title flex-box-center" *ngIf="moduleSetting.heading">{{ moduleSetting.heading }}</p>
      <p class="db-card-desc" *ngIf="moduleSetting.subheading">{{ moduleSetting.subheading }}</p>
    </div>
    <button
      class="button-icon ml-4 card-collapse-button print-d-none"
      [ngClass]="{'button-secondary': !cardOpen}"
      (click)="cardOpen = !cardOpen"
    >
      <app-icon *ngIf="cardOpen" fontSize="36" class="fw-100">up</app-icon>
      <app-icon *ngIf="!cardOpen" fontSize="36" class="fw-100">down</app-icon>
    </button>
  </div>
  <div class="db-card-body">
    <div class="db-card calc-card m-0 border-radius-smooth">
      <div class="chart-c position-relative">
        <ngx-charts-pie-chart
          [view]="[240, 240]"
          [animations]="false"
          [results]="[{name: moduleSetting.homeEquityLabel, value: calculator.homeEquity}, {name: moduleSetting.mortgageLabel + (calculator.isAdditionalCostsEnabled ? ' + Total Cost to Sell': ''), value: ((calculator.mortgage ?? 0) + calculator.totalAdditionalCost)}]"
          [legend]="false"
          [labels]="false"
          [doughnut]="true"
          [arcWidth]="0.1"
          [scheme]="colorScheme"
          [tooltipDisabled]="!!(toolTipDisabled | async)"
        >
          <ng-template #tooltipTemplate let-model="model">
            <p class="m-auto text-size-12 fw-500 pt-2 pb-1 text-color-white">{{model?.name}}</p>
            <p class="m-auto text-size-14 fw-600 pb-2 text-color-white">{{model?.value | currency :'USD' : 'symbol' : '1.0-0'}}</p>
          </ng-template>
        </ngx-charts-pie-chart>
        <div class="stats text-center abs-center">
          <p class="text-size-14 fw-500">{{moduleSetting.homeEquityLabel}}</p>
          <p class="text-size-28 fw-600">{{ (calculator.homeEquity) | currency :'USD' : 'symbol' : '1.0-0'}}</p>
        </div>
      </div>
      <div class="w-100">
        <div class="flex-box-lg-center flex-box-direction-column flex-box-lg-direction-row flex-box-gap-2">
          <p class="text-size-16 fw-500 flex-box-center">
            {{moduleSetting.homeValueLabel}}
            <lib-icon [libTooltip]="moduleSetting.homeValueInfo" class="text-color-primary ml-1 print-d-none">info </lib-icon>
          </p>
          <div class="form-field prefix ml-auto mw-130" data-prefix="$">
            <lib-amount-comma-separated
              name="homeSale"
              class="text-right print-border-none"
              [libMin]="0"
              [libInputMaxValue]="99999999"
              [(ngModel)]="calculator.homeSalePrice"
            ></lib-amount-comma-separated>
          </div>
        </div>
        <mat-slider
          [min]="0"
          [max]="MAX_HOME_SALE_PRICE"
          [(ngModel)]="calculator.homeSalePrice"
          (input)="calculator.homeSalePrice = $event.value!"
          step="1000"
          class="w-100 print-d-none secondary"
        >
          <input matSliderThumb />
        </mat-slider>
        <div class="flex-box-lg-center flex-box-direction-column flex-box-lg-direction-row flex-box-gap-2 mt-4">
          <p class="text-size-16 fw-500 flex-box-center">
            {{moduleSetting.mortgageLabel}}
            <lib-icon [libTooltip]="moduleSetting.mortgageInfo" class="text-color-primary ml-1 print-d-none">info </lib-icon>
          </p>
          <div class="form-field prefix ml-auto mw-130" data-prefix="$">
            <lib-amount-comma-separated
              name="mortgage"
              class="text-right print-border-none"
              [libMin]="0"
              [libInputMaxValue]="99999999"
              [(ngModel)]="calculator.mortgage"
            ></lib-amount-comma-separated>
          </div>
        </div>
        <mat-slider
          [min]="0"
          [max]="calculator.homeSalePrice"
          step="1000"
          class="w-100 print-d-none secondary"
          [(ngModel)]="calculator.mortgage"
          (input)="calculator.mortgage = $event.value!"
        >
          <input matSliderThumb />
        </mat-slider>
      </div>
    </div>
    <div class="cost-c" *ngIf="moduleSetting.showAdditionalCosts">
      <div class="flex-box-center mt-4 mb-3 c-pointer" (click)="additionalCostsOpen = !additionalCostsOpen">
        <p class="text-size-16 fw-600">Est. Total Cost to Sell</p>
        <p class="text-size-18 fw-400 flex-box-center ml-auto">
          {{calculator.totalAdditionalCost | formatPrice}}
          <lib-icon libTooltip="" class="text-color-primary print-d-none"> {{ additionalCostsOpen ? 'up' : 'down'}}</lib-icon>
        </p>
      </div>
      <div [ngClass]="{'d-none' : !additionalCostsOpen}">
        <div class="box-c flex-box-direction-column flex-box-gap-2">
          <ng-container *ngFor="let cost of calculator.additionalCosts; let i = index">
            <div class="flex-box-center cost flex-wrap flex-box-gap-1" *ngIf="cost.showCost">
              <p class="text-size-14 fw-500 flex-box-center">
                {{cost.title}}
                <lib-icon [libTooltip]="cost.infoMessage" class="text-color-primary ml-1 print-d-none"> info</lib-icon>
              </p>
              <div class="flex-box flex-box-gap-2 ml-auto">
                <div class="prefix form-field mw-150" data-prefix="%">
                  <input
                    class="text-right"
                    type="number"
                    [libMin]="0"
                    name=""
                    [libInputMaxValue]="100"
                    [ngModel]="calculator.getPercentageValue(i)"
                    (ngModelChange)="calculator.setPercentageValue(i, $event);"
                  />
                </div>
                <div class="prefix form-field mw-150" data-prefix="$">
                  <lib-amount-comma-separated
                    class="text-right"
                    name=""
                    [libMin]="0"
                    [libInputMaxValue]="calculator.homeSalePrice"
                    [ngModel]="calculator.getAmountValue(i)"
                    (ngModelChange)="calculator.setAmountValue(i, $event);"
                  ></lib-amount-comma-separated>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <p class="text-size-12 fw-400 mt-2 text-color-light-2">
      All calculations are estimates and provided for informational purposes only. Actual amounts may vary.
    </p>
    <div class="footer mt-4 flex-box-center flex-box-gap-3 print-d-none" *ngIf="moduleSetting.showCTAButton">
      <p
        class="text-size-14 fw-600 text-color-light-2"
        *ngIf="moduleSetting.ctaButtonCallOutText"
        [innerHTML]="moduleSetting.ctaButtonCallOutText"
      ></p>
      <button
        class="whitespace-nowrap ml-auto button-mid mt-3"
        [ngClass]="{'button-primary': moduleSetting.ctaButton?.type === DashboardModuleButtonType.SECONDARY}"
        (click)="performCTAAction(moduleSetting.ctaButton!.action)"
      >
        {{ moduleSetting.ctaButton!.label }}
      </button>
    </div>
  </div>
</div>

import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AgentHomewardOfferDetail, FelloConnectedApp} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";

@Injectable({providedIn: "root"})
export class HomewardAppApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/apps/homeward`;
  }

  connectHomewardApp(): Observable<FelloConnectedApp> {
    return this.http.post<FelloConnectedApp>(`${this.baseUrl}/connect`, {});
  }

  getAgentHomewardOfferDetail(propertyId: string): Observable<AgentHomewardOfferDetail> {
    return this.http.get<AgentHomewardOfferDetail>(`${this.baseUrl}/property/${propertyId}/details`);
  }

  requestPrelimOffer(propertyId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/property/${propertyId}/request-offer`, {});
  }

  finalizeOffer(propertyId: string): Observable<{offerFinalizationLink: string}> {
    return this.http.post<{offerFinalizationLink: string}>(`${this.baseUrl}/property/${propertyId}/finalize-offer`, {});
  }
}

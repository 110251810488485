import {ChangelogTag} from "./ChangelogTag";
import {assign, forEach} from "lodash-es";
import {ChangelogTagType} from "./ChangelogTagType";

export enum ChangelogTriggerByTypeEnum {
  ADMIN = "Admin",
  SYSTEM = "System",
  AGENT = "Agent",
  FELLO_CONTACT = "FelloContact",
  AGENT_CONTACT = "AgentContact",
  CRM_APP = "CrmApp"
}

export interface ChangelogTriggeredBy {
  type: ChangelogTriggerByTypeEnum;
  value: string;
}
export type ChangelogTagRecord = Partial<Record<ChangelogTagType, string | null>>;

export interface ChangelogEvent {
  _id?: string; // can act as an idempotencyKey
  triggeredBy: ChangelogTriggeredBy;
  eventType: string;
  eventData: any; //todo change
  tags: ChangelogTag[];
  archived?: boolean;
  createdAt?: string;
  tagsObj: Partial<Record<ChangelogTagType, string>>;
}

export class ExtendedChangelogEvent implements ChangelogEvent {
  _id?: string; // can act as an idempotencyKey
  triggeredBy: ChangelogTriggeredBy;
  eventType: string;
  eventData: any; //todo change
  tags: ChangelogTag[];
  archived?: boolean;
  createdAt: string;
  tagsObj: Partial<Record<ChangelogTagType, string>>;

  static fromChangelogEvent(event: ChangelogEvent): ExtendedChangelogEvent {
    return new ExtendedChangelogEvent(event);
  }

  constructor(event: ChangelogEvent) {
    assign(this, event);
    this.tagsObj = {};
    forEach(this.tags, tag => (this.tagsObj[tag.type] = tag.value));
  }
}

import {Component, ElementRef} from "@angular/core";

import {DashboardModuleButtonAction, DashboardModuleType} from "fello-model";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib";
import {AbstractDashboardService} from "../../services";

const CashOfferBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.PRIMARY_CTA> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-cash-offer",
  templateUrl: "./cash-offer.component.html",
  styleUrls: ["./cash-offer.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs
})
export class CashOfferComponent extends CashOfferBaseComponent {
  constructor(
    private dashboardService: AbstractDashboardService,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar,
    elementRef: ElementRef
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  performCTAAction(action: DashboardModuleButtonAction): void {
    this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
  }
}

export interface AutomationDefinitionPreTurnOnMeta {
  existingMatchingCount: number;
  allMatchingCount: number;
}

export interface AutomationRunningInstancesResponse {
  count: number;
}

export enum AutomationExternalTaskProcessingQueueReason {
  AUTOMATION_OFF = "AUTOMATION_OFF",
  CREDIT_INSUFF = "CREDIT_INSUFF",
  BUDGET_INSUFF = "BUDGET_INSUFF",
  EMAIL_PO_INSUFF = "EMAIL_PO_INSUFF"
}

export const AutomationExternalTaskProcessingQueueReasonLabel: Record<AutomationExternalTaskProcessingQueueReason, string> = {
  AUTOMATION_OFF: "Automation Off",
  CREDIT_INSUFF: "Insufficient Credits",
  BUDGET_INSUFF: "Budget Exhausted",
  EMAIL_PO_INSUFF: "Daily sending limit reached"
};

export interface AutomationPendingContactWrapper {
  contactId: string;
  propertyId?: string;
  processId: string;
  completionDueDate?: Date;
  queueReason: AutomationExternalTaskProcessingQueueReason;
}

import {Injectable, Injector} from "@angular/core";
import {Observable} from "rxjs";
import {AgentContactSystemTagToLabelMapping, FieldFilterAllowedValue, FieldFilterDynamicListType} from "fello-model";
import {map} from "rxjs/operators";
import {AgencyApiService, AgentContactApiService, Constructor} from "../../../lib";
import {BaseDynamicList} from "./fql-base-dynamic-list-factory.service";
import {AbstractFqlDynamicListFactoryService} from "./abstract-fql-dynamic-list-factory.service";
import {AgentStringUtils} from "../../fello-ui-utils";

@Injectable()
class ContactOwnerDynamicList extends BaseDynamicList {
  constructor(private agencyApiService: AgencyApiService) {
    super();
  }
  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    return this.agencyApiService.getAllAgencyMembers().pipe(
      map(members => {
        const sortedMembers = AgentStringUtils.sortAgentsByInvite(members);
        return [
          ...sortedMembers.map(member => ({
            label: AgentStringUtils.labelAgentsByOnboardingStep(member),
            value: member._id!
          })),
          {
            label: "Unassigned",
            value: null
          }
        ];
      })
    );
  }
}

@Injectable()
class ContactCRMAgentNamesDynamicList extends BaseDynamicList {
  constructor(private contactApiService: AgentContactApiService) {
    super();
  }
  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    return this.contactApiService.getCrmAgentNames().pipe(
      map(members => {
        return members.map(member => ({
          label: member.name || member.email!,
          value: member._id!
        }));
      })
    );
  }
}
@Injectable()
class ContactTagsDynamicList extends BaseDynamicList {
  constructor(private contactApiService: AgentContactApiService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    return this.contactApiService.tagWiseCounts({}).pipe(
      map(tagWiseCountResponse => {
        return tagWiseCountResponse.records
          .filter(t => t.tagName)
          .map(tagCount => {
            return {
              label: AgentContactSystemTagToLabelMapping[tagCount.tagName] ?? tagCount.tagName,
              value: tagCount.tagName
            };
          });
      })
    );
  }
}

@Injectable()
class ContactCrmNamesDynamicList extends BaseDynamicList {
  constructor(private contactApiService: AgentContactApiService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    return this.contactApiService.getCrmFields({}, "name").pipe(
      map(crmNames => {
        return crmNames
          .filter(t => !!t)
          .map(crmName => {
            return {
              label: crmName,
              value: crmName
            };
          });
      })
    );
  }
}

@Injectable()
class ContactCRMSourcesDynamicList extends BaseDynamicList {
  constructor(private contactApiService: AgentContactApiService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    return this.contactApiService.getCrmFields({}, "source").pipe(
      map(crmSources => {
        return crmSources
          .filter(t => !!t)
          .map(crmSource => {
            return {
              label: crmSource,
              value: crmSource
            };
          });
      })
    );
  }
}

@Injectable()
class ContactCRMStagesDynamicList extends BaseDynamicList {
  constructor(private contactApiService: AgentContactApiService) {
    super();
  }

  getAllListItems(): Observable<FieldFilterAllowedValue[]> {
    return this.contactApiService.getCrmFields({}, "stage").pipe(
      map(crmStages => {
        return crmStages
          .filter(t => !!t)
          .map(crmStage => {
            return {
              label: crmStage,
              value: crmStage
            };
          });
      })
    );
  }
}

@Injectable()
export class FqlContactDynamicListFactoryService extends AbstractFqlDynamicListFactoryService {
  public static readonly listTypeToServiceMap: Record<FieldFilterDynamicListType, Constructor<BaseDynamicList>> = {
    [FieldFilterDynamicListType.CONTACT_OWNERS]: ContactOwnerDynamicList,
    [FieldFilterDynamicListType.CONTACT_CRM_AGENTS]: ContactCRMAgentNamesDynamicList,
    [FieldFilterDynamicListType.CONTACT_TAGS]: ContactTagsDynamicList,
    [FieldFilterDynamicListType.CONTACT_CRM_NAMES]: ContactCrmNamesDynamicList,
    [FieldFilterDynamicListType.CONTACT_CRM_SOURCES]: ContactCRMSourcesDynamicList,
    [FieldFilterDynamicListType.CONTACT_CRM_STAGES]: ContactCRMStagesDynamicList
  };

  constructor(private injector: Injector) {
    super();
  }

  getDynamicList(dynamicListType: FieldFilterDynamicListType): BaseDynamicList {
    return this.injector.get(FqlContactDynamicListFactoryService.listTypeToServiceMap[dynamicListType]);
  }
}

export const FqlDynamicListHelperProviders = [
  ContactOwnerDynamicList,
  ContactCRMAgentNamesDynamicList,
  ContactTagsDynamicList,
  ContactCrmNamesDynamicList,
  ContactCRMStagesDynamicList,
  ContactCRMSourcesDynamicList,
  FqlContactDynamicListFactoryService,
  {
    provide: AbstractFqlDynamicListFactoryService,
    useExisting: FqlContactDynamicListFactoryService
  }
];

<ngx-skeleton-loader *ngIf="isLoading; else isLoaded" count="1" [theme]="{  height: '90px', width: '100%' }"></ngx-skeleton-loader>
<ng-template #isLoaded>
  <ng-container>
    <div class="db-card">
      <div class="db-card-header">
        <div>
          <p class="db-card-title fw-600 text-size-20">{{ moduleSetting.heading }}</p>
          <p class="text-size-14 fw-500 text-color-light-2 mt-1" *ngIf="moduleSetting.subheading">{{ moduleSetting.subheading }}</p>
        </div>
      </div>
      <div class="db-card-body m-0">
        <ng-container>
          <div class="flex-box-direction-column flex-box-lg-direction-row flex-box-gap-4 flex-box-lg-gap-2 mt-3 mb-3">
            <div class="w-100 flex-box-direction-column flex-box-gap-1" *ngFor="let stepProcess of moduleSetting.stepProcesses">
              <img class="mb-1 step-image w-100" [src]="stepProcess.image | mediaWithType:{auto: 'compress', fit: 'clip'}" />
              <p class="fw-700 text-size-14 text-color-primary">{{stepProcess.title}}</p>
              <p class="text-size-16 fw-600">{{stepProcess.subTitle}}</p>
              <p class="text-size-14 fw-500 text-color-light-2">{{stepProcess.text}}</p>
            </div>
          </div>
          <div class="mt-auto print-d-none">
            <button
              class="whitespace-nowrap ml-auto button-mid"
              [ngClass]="{'button-primary': moduleSetting.button.type === DashboardModuleButtonType.SECONDARY}"
              (click)="performCTAAction(moduleSetting.button.action)"
            >
              {{ moduleSetting.button.label }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

export enum FelloConnectOrigin {
  FC = "FC",
  CRMX = "CRMX",
  ENGAGE = "EN",
  ENRICH = "ENR",
  LP = "LP",
  WEB = "WEB", // Property Dashboard
  PURL = "PURL",
  REFERRAL = "REFERRAL",
  T = "T",
  WIDGET = "WGT",
  AUTOMATION = "AUTOMATION"
}

export const FelloConnectOriginLabel: Record<FelloConnectOrigin, string> = {
  [FelloConnectOrigin.FC]: "Connect",
  [FelloConnectOrigin.CRMX]: "CRM Integration",
  [FelloConnectOrigin.ENGAGE]: "Engage",
  [FelloConnectOrigin.ENRICH]: "Enrich",
  [FelloConnectOrigin.LP]: "Landing Page",
  [FelloConnectOrigin.WEB]: "Contact Dashboard",
  [FelloConnectOrigin.PURL]: "Personalized URL",
  [FelloConnectOrigin.REFERRAL]: "Referral",
  [FelloConnectOrigin.T]: "Turbo",
  [FelloConnectOrigin.WIDGET]: "Widget",
  [FelloConnectOrigin.AUTOMATION]: "Automation"
};

import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewContainerRef} from "@angular/core";
import {take, takeUntil, tap} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {mixinDestroyable} from "../../../../lib";
import {AgentSignatureType, DashboardModuleButtonType, DashboardModuleType, FelloUserContactDetails} from "fello-model";
import {ContactAgentComponent} from "../dialogs";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {AbstractDashboardService} from "../../services";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: "lib-real-estate-agent",
  templateUrl: "./real-estate-agent.component.html",
  styleUrls: ["./real-estate-agent.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs
})
export class RealEstateAgentComponent
  extends mixinDestroyable(class extends DashboardModuleDirective<DashboardModuleType.CONTACT_AGENT> {})
  implements OnInit
{
  isLoading = true;
  agentSignature$ = this.dashboardService.agentSignature$;
  AgentSignatureType = AgentSignatureType;
  DashboardModuleButtonType = DashboardModuleButtonType;

  constructor(
    private dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    elementRef: ElementRef,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super(elementRef);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.dashboardService.isPropertyLoading
        .pipe(
          takeUntil(this.isDestroyed),
          tap(val => (this.isLoading = val))
        )
        .subscribe(
          () => {},
          () => (this.isLoading = false)
        );
    }
  }

  openContactAgent() {
    this.dashboardService.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.dialog.open(ContactAgentComponent, {
            maxWidth: 580,
            data: {
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.emailId
            } as FelloUserContactDetails,
            viewContainerRef: this.viewContainerRef,
            panelClass: ["mobile-bottom-sheet"]
          });
        })
      )
      .subscribe();
  }
}

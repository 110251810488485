import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewContainerRef} from "@angular/core";
import {mixinDestroyable} from "../../../../lib/mixins/destroy";
import {take, takeUntil, tap} from "rxjs/operators";
import {AgentSignatureType, DashboardHomeFactsUpdateRequest, DashboardModuleType, FelloUserContactDetails} from "fello-model";
import {MatDialog} from "@angular/material/dialog";
import {HomeDetailsEditDialogComponent} from "./home-details-edit-dialog/home-details-edit-dialog.component";
import {SuccessDialogComponent} from "../dialogs/success-dialog/success-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DashboardHomeFactsRequest} from "./DashboardHomeFactsRequest";
import {ContactAgentComponent, ImproveHomeEstimateComponent} from "../dialogs";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {AbstractDashboardService} from "../../services";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: "app-home-details",
  templateUrl: "./home-details.component.html",
  styleUrls: ["./home-details.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs
})
export class HomeDetailsComponent
  extends mixinDestroyable(class extends DashboardModuleDirective<DashboardModuleType.HOME_DETAILS> {})
  implements OnInit
{
  isLoading = true;
  cardOpen = true;
  property$ = this.dashboardService.property$;
  homeFacts: DashboardHomeFactsRequest = {};
  agentSignature$ = this.dashboardService.agentSignature$;
  AgentSignatureType = AgentSignatureType;

  constructor(
    private dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private viewContainerRef: ViewContainerRef,
    elementRef: ElementRef,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    super(elementRef);
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.dashboardService.isPropertyLoading
        .pipe(
          takeUntil(this.isDestroyed),
          tap(val => (this.isLoading = val))
        )
        .subscribe(
          () => {},
          () => (this.isLoading = false)
        );
    }
  }

  openEditHomeDetailsDialog(): void {
    this.property$
      .pipe(
        take(1),
        tap(property => {
          const vars = property.vars;
          this.homeFacts.baths = vars.baths;
          this.homeFacts.beds = vars.beds;
          this.homeFacts.sqft = vars.sqft;
          this.homeFacts.partialBaths = vars.partialBaths;
          this.homeFacts.storiesCount = vars.storiesCount;
          this.homeFacts.yearBuilt = vars.yearBuilt;
          this.homeFacts.kitchenCondition = vars.kitchenCondition;
          this.homeFacts.bathroomCondition = vars.bathroomCondition;
          this.homeFacts.flooringCondition = vars.flooringCondition;
          this.homeFacts.homePaintCondition = vars.homePaintCondition;
          this.homeFacts.overallCondition = vars.overallCondition;
          this.homeFacts.additionalBathroomCondition = vars.additionalBathroomCondition;
          this.homeFacts.hoa = vars.hoa;
          this.homeFacts.wellWater = vars.wellWater;
          this.homeFacts.septicSystem = vars.septicSystem;
          this.homeFacts.solarPanels = vars.solarPanels;
          this.homeFacts.swimmingPool = vars.swimmingPool;
          this.homeFacts.homeSecurity = vars.homeSecurity;
          this.homeFacts.hotTubSpa = vars.hotTubSpa;

          const dialogRef = this.dialog.open(HomeDetailsEditDialogComponent, {
            data: this.homeFacts,
            panelClass: ["dialog-full-screen"]
          });

          dialogRef
            .afterClosed()
            .pipe(
              tap((facts: DashboardHomeFactsUpdateRequest) => {
                if (facts) {
                  this.updateFacts(facts);
                }
              })
            )
            .subscribe();
        })
      )
      .subscribe();
  }

  updateFacts(facts: DashboardHomeFactsUpdateRequest): void {
    this.isLoading = true;
    this.dashboardService.updateHomeFacts(facts).subscribe(
      () => {
        this.openHomeEditsSuccessful();
        this.isLoading = false;
      },
      () => {
        this.snackBar.open("Failed to update home facts", undefined, {duration: 3000});
        this.isLoading = false;
      }
    );
  }

  openHomeEditsSuccessful() {
    this.dialog.open(SuccessDialogComponent, {
      data: {
        title: "Successful",
        desc: "Your home facts have been successfully updated.",
        buttonText: "Continue"
      },
      panelClass: ["mobile-bottom-sheet"]
    });
  }

  openContactAgent() {
    this.dashboardService.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.dialog.open(ContactAgentComponent, {
            maxWidth: 580,
            data: {
              fullName: contact.fullName,
              phone: contact.phone,
              email: contact.emailId
            } as FelloUserContactDetails,
            viewContainerRef: this.viewContainerRef
          });
        })
      )
      .subscribe();
  }

  openImproveEstimate(): void {
    this.dialog.open(ImproveHomeEstimateComponent, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet"]
    });
  }
}

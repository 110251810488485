import {shareReplay} from "rxjs/operators";

export function cacheResponse(target: any, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
  const originalMethod = descriptor.value;
  descriptor.value = function (): any {
    const self = this as any;
    const cacheKey = JSON.stringify(arguments);
    if (!self[propertyKey].cache) {
      self[propertyKey].cache = {};
    }
    if (!self[propertyKey].cache.hasOwnProperty(cacheKey)) {
      return (self[propertyKey].cache[cacheKey] = originalMethod.apply(this, arguments).pipe(shareReplay()));
    }
    return self[propertyKey].cache[cacheKey];
  };
  return descriptor;
}

<div class="db-card" *ngIf="this.isLoading">
  <ngx-skeleton-loader count="1" [theme]="{  height: '58px', width: '100%' }"></ngx-skeleton-loader>
  <div class="db-card-body">
    <ngx-skeleton-loader class="mb-4 map-c" count="1" [theme]="{  height: '290px', width: '100%' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader class="comps-c" count="4" [theme]="{  height: '290px', width: '100%' }"></ngx-skeleton-loader>
  </div>
</div>
<div *ngIf="!this.isLoading && {currentProperty:property$ | async ,comparables: comparables$ | async} as compCardVariables">
  <div
    class="db-card page-break-inside-avoid"
    [ngClass]="{'collapse': !cardOpen}"
    id="comparablesCard"
    *ngIf="compCardVariables.comparables && compCardVariables.comparables.length>0 && compCardVariables.currentProperty"
  >
    <div class="flex-box-between db-card-header">
      <div>
        <p class="db-card-title">{{moduleSetting.heading}}</p>
        <p class="db-card-desc">{{moduleSetting.subheading}}</p>
      </div>
      <button
        class="button-icon ml-4 card-collapse-button print-d-none"
        [ngClass]="{'button-secondary': !cardOpen}"
        (click)="cardOpen = !cardOpen"
      >
        <app-icon *ngIf="cardOpen" fontSize="36" class="fw-100">up</app-icon>
        <app-icon *ngIf="!cardOpen" fontSize="36" class="fw-100">down</app-icon>
      </button>
    </div>
    <div class="db-card-body">
      <div class="map-c mb-4 page-break-inside-avoid actual-data" *libIsPlatformBrowser>
        <ng-container *libIsGoogleMapsLoaded>
          <google-map
            [center]="center"
            *ngIf="(mapCenter | async); let center"
            [width]="'100%'"
            [height]="'100%'"
            [zoom]="mapZoom"
            #googleMap
            (mapClick)="focusComp(null)"
            [options]="{disableDefaultUI: true,mapTypeId: 'roadmap', zoomControl: true,scrollwheel: false}"
          >
            <map-marker
              #sourcePropertyMarker
              *ngIf="compCardVariables.currentProperty"
              [position]="{lat: compCardVariables.currentProperty.addressComponents!.latitude, lng:compCardVariables.currentProperty.addressComponents!.longitude}"
              [options]="{icon: sourcePropertyMarkerIcon, title: (compCardVariables.currentProperty.addressComponents.doorNumber)+ (compCardVariables.currentProperty.addressComponents.street)}"
            >
            </map-marker>
            <map-marker
              *ngFor="let comp of compCardVariables.comparables;let i = index;"
              [options]="{icon: getMapIcons(comp), label:{color:'var(--text-color)',className:'map-marker-label actual-data',text:(i|numberToLetter)+'/'+ (comp.opd.recentSaleOrListPrice! | numberShorthand)}}"
              [position]=" {lat: comp.opd.lat ?? 0, lng: comp.opd.long ?? 0}"
              (mapClick)="focusComp(comp)"
            >
            </map-marker>
            <map-marker
              *ngFor="let comp of compCardVariables.comparables;let i = index;"
              [options]="{icon: getMapIcons(comp), label:{color:'var(--text-color)',className:'map-marker-label dummy-data',text:(i|numberToLetter)+'/'+ 'XXXk'}}"
              [position]=" {lat: comp.opd.lat ?? 0, lng: comp.opd.long ?? 0}"
              (mapClick)="focusComp(comp)"
            >
            </map-marker>
          </google-map>
        </ng-container>
      </div>
      <lib-comparables-map-dummy class="dummy-data mb-4 map-c"></lib-comparables-map-dummy>
      <div class="comps-c" *ngIf="compCardVariables.currentProperty">
        <app-comp-card
          [sourcePropertyOPDLite]="compCardVariables.currentProperty.homeFacts!.opdLite!"
          [comp]="comp"
          [index]="i"
          class="page-break-inside-avoid"
          (click)="compSelected(comp)"
          *ngFor="let comp of compCardVariables.comparables;let i = index"
        ></app-comp-card>
      </div>

      <ng-container *ngIf="moduleSetting.showCTA">
        <div class="line-break print-d-none"></div>
        <button
          class="link text-center w-100 fw-500 text-size-14 print-d-none actual-data"
          *ngIf="agentSignature$ | async ;let agentSignature"
          (click)="openContactAgent()"
        >
          {{moduleSetting.linkText}}
        </button>
        <button class="link text-center w-100 fw-500 text-size-14 print-d-none dummy-data">{{moduleSetting.linkText}}</button>
      </ng-container>
    </div>
  </div>
</div>

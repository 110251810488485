import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AgentCashOfferWrapper,
  AgentSignature,
  AVMMultiHistoryInfo,
  ChangelogEventType,
  CMAFromDashboardRequest,
  CompLiteWithHvEstimate,
  ContactAgentFromDashboardRequest,
  ContactDetailsOnlyFormConfigSettings,
  DashboardHomeFactsUpdateRequest,
  DashboardModuleButtonAction,
  DashboardSettingsResponse,
  FelloConnectedAppType,
  FelloMultiAvmResponse,
  HomeEstimates,
  LeadRequestFromDashboard,
  PropertyAndContact,
  PropertyHomeEquityResponse,
  SellingOptionsResponse,
  TrackTPModuleClickRequest
} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "../agent";

@Injectable({
  providedIn: "root"
})
export class PropertyPublicDashboardApiService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/property-dashboard`;
  }

  get404PageUrl(): Observable<{url: string}> {
    return this.http.get<{url: string}>(`${this.baseUrl}/404-page`);
  }

  getPropertyAndContact(propertyId: string): Observable<PropertyAndContact> {
    return this.http.get<PropertyAndContact>(`${this.baseUrl}/${propertyId}/property-and-contact`);
  }

  getAgentCashOfferWrapper(propertyId: string): Observable<AgentCashOfferWrapper> {
    return this.http.get<AgentCashOfferWrapper>(`${this.baseUrl}/${propertyId}/offer-wrapper`);
  }

  getSignature(propertyId: string): Observable<AgentSignature> {
    return this.http.get<AgentSignature>(`${this.baseUrl}/${propertyId}/signature`);
  }

  getPropertyDashboardSettings(propertyId: string): Observable<DashboardSettingsResponse | null> {
    return this.http.get<DashboardSettingsResponse | null>(`${this.baseUrl}/${propertyId}/settings`);
  }

  getSellingOptions(propertyId: string): Observable<SellingOptionsResponse> {
    return this.http.get<SellingOptionsResponse>(`${this.baseUrl}/${propertyId}/selling-options`);
  }

  getHVFormSettings(propertyId: string): Observable<ContactDetailsOnlyFormConfigSettings> {
    return this.http.get<ContactDetailsOnlyFormConfigSettings>(`${this.baseUrl}/${propertyId}/hv-form-settings`);
  }

  getHomeEstimate(propertyId: string): Observable<HomeEstimates> {
    return this.http.get<HomeEstimates>(`${this.baseUrl}/${propertyId}/hv-est`);
  }

  // getAllHomeEstimates(propertyId: string): Observable<FelloMultiAvmResponse> {
  //   return this.http.get<FelloMultiAvmResponse>(`${this.baseUrl}/${propertyId}/hv-est-all`);
  // }

  getAllHomeEstimates(propertyId: string): Observable<FelloMultiAvmResponse> {
    return this.http.get<FelloMultiAvmResponse>(`${this.baseUrl}/${propertyId}/hv-est-all-v2`);
  }

  getCompsWithEstimate(propertyId: string): Observable<CompLiteWithHvEstimate> {
    return this.http.get<CompLiteWithHvEstimate>(`${this.baseUrl}/${propertyId}/comps-w-est`);
  }

  getEquityDetails(propertyId: string): Observable<PropertyHomeEquityResponse> {
    return this.http.get<PropertyHomeEquityResponse>(`${this.baseUrl}/${propertyId}/equity`);
  }

  getMultiAvmHistoryDetail(propertyId: string): Observable<AVMMultiHistoryInfo> {
    return this.http.get<AVMMultiHistoryInfo>(`${this.baseUrl}/${propertyId}/avm-histories`);
  }

  getMarketData(propertyId: string): Observable<unknown> {
    return this.http.get(`${this.baseUrl}/${propertyId}/market`);
  }

  updateHomeFacts(propertyId: string, facts: DashboardHomeFactsUpdateRequest): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/${propertyId}/home-facts`, {
      facts
    });
  }

  updateBuyingWithSelling(propertyId: string, buyingArea: string, buyingPriceLow: number, buyingPriceHigh: number): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/${propertyId}/buying-with-selling-opt`, {
      buyingArea,
      buyingPriceLow,
      buyingPriceHigh
    });
  }

  trackDashboardEvent(propertyId: string, eventType: ChangelogEventType, eventData?: any): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${propertyId}/event/track`, {eventType, eventData});
  }

  submitContactAgentForm(
    propertyId: string,
    eventType: ChangelogEventType,
    contactAgentFromDashboardRequest: ContactAgentFromDashboardRequest
  ): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${propertyId}/contact-agent`, {
      eventType,
      contactAgentFromDashboardRequest
    });
  }

  submitRequestCMAForm(propertyId: string, cmaRequest: CMAFromDashboardRequest): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${propertyId}/request-cma`, cmaRequest);
  }

  requestInstantOfferForm(propertyId: string): Observable<{journeyId: string}> {
    return this.http.post<{journeyId: string}>(`${this.baseUrl}/${propertyId}/request-offer`, {});
  }

  resolveCTAAction(propertyId: string, action: DashboardModuleButtonAction): Observable<{url: string}> {
    return this.http.post<{url: string}>(`${this.baseUrl}/${propertyId}/cta/action`, {action});
  }

  submitCTAPopupLeadForm(
    propertyId: string,
    action: DashboardModuleButtonAction,
    data: LeadRequestFromDashboard,
    connectedAppType?: FelloConnectedAppType
  ): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${propertyId}/cta/popup-lead-form`, {
      action,
      popupFormDashboardRequest: data,
      connectedAppType
    });
  }

  trackTPModuleClick(propertyId: string, trackRequest: TrackTPModuleClickRequest): Observable<{_id: string}> {
    return this.http.post<{_id: string}>(`${this.baseUrl}/${propertyId}/track-tp-module-click`, trackRequest);
  }

  getImage(propertyId: string): Observable<{url: string | null}> {
    return this.http.get<{url: string | null}>(`${this.baseUrl}/${propertyId}/img`);
  }
}


export enum AutomationExternalTaskProcessingSkipReason {
  component_off = "component_off",
  entity_enr_criteria_fail = "entity_enr_criteria_fail",
  no_property = "no_property",
  smart_send = "smart_send",
  no_email = "no_email",
  user_unsub = "user_unsub",
  email_inv = "email_inv",
  plan_inactive = "plan_inactive"
}
export const AutomationExternalTaskProcessingSkipReasonToLabelMapping: Record<AutomationExternalTaskProcessingSkipReason, string> = {
  [AutomationExternalTaskProcessingSkipReason.component_off]: "Action Disabled",
  [AutomationExternalTaskProcessingSkipReason.entity_enr_criteria_fail]: "Enrollment Criteria",
  [AutomationExternalTaskProcessingSkipReason.no_property]: "Property not present",
  [AutomationExternalTaskProcessingSkipReason.smart_send]: "Smart Send",
  [AutomationExternalTaskProcessingSkipReason.no_email]: "No Email",
  [AutomationExternalTaskProcessingSkipReason.user_unsub]: "User Unsubscribed email",
  [AutomationExternalTaskProcessingSkipReason.email_inv]: "Invalid Email",
  [AutomationExternalTaskProcessingSkipReason.plan_inactive]: "Account Subscription Inactive",
}

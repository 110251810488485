import {AutomationTwoWayBranchComponentSettings} from "./settings/AutomationTwoWayBranchComponentSettings";
import {AutomationWaitComponentSettings} from "./settings/AutomationWaitComponentSettings";
import {AutomationPostcardComponentSettings} from "./settings/AutomationPostcardComponentSettings";
import {AutomationBaseComponentSettings} from "./AutomationBaseComponentSettings";
import {AutomationCallWebhookComponentSettings} from "./settings/AutomationCallWebhookComponentSettings";
import {AutomationEmailComponentSettings} from "./settings/AutomationEmailComponentSettings";

export enum AutomationComponentType {
  START = "START",
  TWO_WAY_BRANCH = "TWO_WAY_BRANCH",
  POSTCARD = "POSTCARD",
  EMAIL = "EMAIL",
  CALL_WEBHOOK = "CALL_WEBHOOK",
  GOTO = "GOTO",
  LOG = "LOG",
  WAIT = "WAIT",
  END = "END"
}

export const ActionableComponentTypes: AutomationComponentType[] = [
  AutomationComponentType.EMAIL,
  AutomationComponentType.POSTCARD,
  AutomationComponentType.CALL_WEBHOOK
];
export const EditableComponentTypes: AutomationComponentType[] = [
  AutomationComponentType.CALL_WEBHOOK,
  AutomationComponentType.START,
  AutomationComponentType.WAIT,
  AutomationComponentType.TWO_WAY_BRANCH,
  AutomationComponentType.POSTCARD,
  AutomationComponentType.EMAIL
];

export const ResizableComponentTypes: AutomationComponentType[] = [AutomationComponentType.START, AutomationComponentType.TWO_WAY_BRANCH];

export interface AutomationComponentTypeToSettingMap {
  [AutomationComponentType.WAIT]: AutomationWaitComponentSettings;
  [AutomationComponentType.TWO_WAY_BRANCH]: AutomationTwoWayBranchComponentSettings;
  [AutomationComponentType.POSTCARD]: AutomationPostcardComponentSettings;
  [AutomationComponentType.EMAIL]: AutomationEmailComponentSettings;
  [AutomationComponentType.START]: AutomationBaseComponentSettings;
  [AutomationComponentType.LOG]: AutomationBaseComponentSettings;
  [AutomationComponentType.GOTO]: AutomationBaseComponentSettings;
  [AutomationComponentType.END]: AutomationBaseComponentSettings;
  [AutomationComponentType.CALL_WEBHOOK]: AutomationCallWebhookComponentSettings;
}

export type AvailableAutomationComponentTypes = keyof AutomationComponentTypeToSettingMap;
export type AutomationComponentSettings<AutomationComponentType extends AvailableAutomationComponentTypes> =
  AutomationComponentTypeToSettingMap[AutomationComponentType];

export enum AutomationComponentActionTarget {
  CONTACT = "CONTACT",
  CONTACT_1P = "CONTACT_1P",
  CONTACT_ALL_P = "CONTACT_ALL_P",
  PROPERTY = "PROPERTY"
}

export enum AutomationComponentStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export enum AutomationComponentSetupStatus {
  TODO = "TODO",
  DONE = "DONE"
}

export const AutomationComponentSetupStatusLabel: Record<string, string> = {
  [AutomationComponentSetupStatus.TODO]: "Changes needed",
  [AutomationComponentSetupStatus.DONE]: "Ready"
};

export interface AutomationComponentDiagramMeta {
  diagProvider: string;
  meta: any;
}

export const AutomationComponentTypeLabel: Record<string, string> = {
  [AutomationComponentType.END]: "End",
  [AutomationComponentType.LOG]: "Log",
  [AutomationComponentType.GOTO]: "Go To",
  [AutomationComponentType.WAIT]: "Delay",
  [AutomationComponentType.TWO_WAY_BRANCH]: "Branch",
  [AutomationComponentType.POSTCARD]: "Send Postcard",
  [AutomationComponentType.EMAIL]: "Send Email",
  [AutomationComponentType.START]: "Enrollment Triggers",
  [AutomationComponentType.CALL_WEBHOOK]: "Webhook"
};

export interface AutomationComponent<AutomationComponentType extends AvailableAutomationComponentTypes> {
  _id: string;
  title: string;
  agencyId?: string;
  wfTaskIds?: string[];
  definitionId: string;
  type: AutomationComponentType;
  status: AutomationComponentStatus;
  setupStatus: AutomationComponentSetupStatus;
  settings?: AutomationComponentSettings<AutomationComponentType>;
  diag?: AutomationComponentDiagramMeta;
  createdAt: Date;
  updatedAt: Date;
}

export enum PricingModelType {
  FlatFee = "flat_fee",
  PerUnit = "per_unit",
  Tiered = "tiered",
  Volume = "volume",
  StairStep = "stairstep"
}

export interface FlatFeePricingModel {
  modelType: PricingModelType.FlatFee;

  price: number;
}

export interface PerUnitPricingModel {
  modelType: PricingModelType.PerUnit;

  price: number;

  max: number;

  stepSize?: number;
}

export interface TieredPricingModel {
  modelType: PricingModelType.Tiered | PricingModelType.Volume | PricingModelType.StairStep;

  tiers: {
    start: number;
    end?: number;
    price: number;
  }[];

  allowedValues: number[];
}

export type PricingModel = FlatFeePricingModel | PerUnitPricingModel | TieredPricingModel;

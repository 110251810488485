import {Directive, forwardRef} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {of} from "rxjs";
import {catchError, distinctUntilChanged, map, takeUntil} from "rxjs/operators";
import {FieldExprContainerService} from "../services";
import {DestroyableBase} from "../../../lib/mixins";

@Directive({
  selector: "[appFieldExprValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FieldExprValidatorDirective),
      multi: true
    },
    FieldExprContainerService
  ],
  standalone: true
})
export class FieldExprValidatorDirective extends DestroyableBase implements Validator {
  private _fieldExpressionInEdit = false;
  private _onChange: () => void;

  constructor(private fieldExprContainerService: FieldExprContainerService) {
    super();
    this.fieldExprContainerService.totalExprUnderEdit$
      .pipe(
        takeUntil(this.isDestroyed),
        catchError(() => {
          this._fieldExpressionInEdit = false;
          return of(0);
        }),
        map(fieldExprCount => fieldExprCount > 0),
        distinctUntilChanged()
      )
      .subscribe(fieldExprInEdit => {
        if (this._onChange) {
          this._fieldExpressionInEdit = fieldExprInEdit;
          this._onChange();
        }
      });
  }

  registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this._fieldExpressionInEdit) {
      return {
        fieldExprInEdit: true
      };
    }
    return null;
  }
}

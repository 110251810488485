import {coerceNumberProperty, NumberInput} from "@angular/cdk/coercion";
import {Directive, forwardRef, Input, OnChanges} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";
import {VIRTUAL_SCROLL_STRATEGY, VirtualScrollStrategy, CdkVirtualScrollViewport} from "@angular/cdk/scrolling";

export class AutoSizeVirtualScrollStrategy implements VirtualScrollStrategy {
  private readonly _scrolledIndexChange = new Subject<number>();

  scrolledIndexChange: Observable<number> = this._scrolledIndexChange.pipe(distinctUntilChanged());
  private _viewport: CdkVirtualScrollViewport | null = null;
  private _itemSizes: number[];
  private _minBufferPx: number;

  constructor(itemSizes: number[], minBufferPx: number) {
    this._itemSizes = itemSizes;
    this._minBufferPx = minBufferPx;
  }

  attach(viewport: CdkVirtualScrollViewport) {
    this._viewport = viewport;
    this._updateTotalContentSize();
    this._updateRenderedRange();
  }

  detach() {
    this._scrolledIndexChange.complete();
    this._viewport = null;
  }

  updateItemAndBufferSize(itemSizes: number[], minBufferPx: number) {
    this._itemSizes = itemSizes;
    this._minBufferPx = minBufferPx;
    this._updateTotalContentSize();
    this._updateRenderedRange();
  }

  onContentScrolled() {
    this._updateRenderedRange();
  }

  onDataLengthChanged() {
    this._updateTotalContentSize();
    this._updateRenderedRange();
  }

  onContentRendered() {
    /* no-op */
  }

  onRenderedOffsetChanged() {
    /* no-op */
  }

  scrollToIndex(index: number, behavior: ScrollBehavior): void {
    if (this._viewport) {
      const cumulativeOffset = this._itemSizes.reduce(
        (acc: number, next: number, currentIndex: number) => (currentIndex < index ? acc + next : acc),
        0
      );
      this._viewport.scrollToOffset(index * cumulativeOffset, behavior);
    }
  }

  private _updateTotalContentSize() {
    if (!this._viewport) {
      return;
    }

    const cumulativeSize = this._itemSizes.reduce((acc: number, next: number) => acc + next, 0);

    this._viewport.setTotalContentSize(cumulativeSize);
  }

  private _updateRenderedRange() {
    if (!this._viewport) {
      return;
    }

    const renderedRange = this._viewport.getRenderedRange();
    const newRange = {start: renderedRange.start, end: renderedRange.end};
    const viewportSize = this._viewport.getViewportSize();
    const scrollOffset = this._viewport.measureScrollOffset();
    let firstVisibleIndex = 0;
    let tempScrollOffset = scrollOffset;

    for (let i = 0; i < this._itemSizes.length; i++) {
      if (tempScrollOffset - this._itemSizes[i] > 0) {
        firstVisibleIndex++;
      }
      tempScrollOffset -= this._itemSizes[i];
    }

    // Find start index
    tempScrollOffset = scrollOffset - this._minBufferPx;
    let newRangeStart = 0;
    for (let i = 0; i < this._itemSizes.length; i++) {
      if (tempScrollOffset - this._itemSizes[i] > 0) {
        newRangeStart++;
      }
      tempScrollOffset -= this._itemSizes[i];
    }

    newRange.start = newRangeStart;

    // Find end index

    tempScrollOffset = scrollOffset + viewportSize + this._minBufferPx;
    let newRangeEnd = 0;
    let i = 0;
    do {
      newRangeEnd++;
      tempScrollOffset -= this._itemSizes[i];
      i++;
    } while (tempScrollOffset > 0);

    newRange.end = newRangeEnd;

    let newRangeStartHeight = 0;
    for (let i = 0; i < newRange.start; i++) {
      newRangeStartHeight += this._itemSizes[i];
    }

    let lastVisibleIndex = firstVisibleIndex;
    let tempViewPort = this._viewport.getViewportSize();
    for (let i = firstVisibleIndex; i < this._itemSizes.length; i++) {
      if (tempViewPort > this._itemSizes[i]) {
        lastVisibleIndex++;
      }
      tempViewPort -= this._itemSizes[i];
    }

    this._viewport.setRenderedRange(newRange);
    this._viewport.setRenderedContentOffset(newRangeStartHeight);
    this._scrolledIndexChange.next(Math.floor(lastVisibleIndex));
  }
}

export function _fixedSizeVirtualScrollStrategyFactory(autoSizeDir: AutoSizeVirtualScrollDirective) {
  return autoSizeDir._scrollStrategy;
}

/* eslint-disable @angular-eslint/directive-selector */
@Directive({
  selector: "cdk-virtual-scroll-viewport[itemSizes]",
  standalone: true,
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: _fixedSizeVirtualScrollStrategyFactory,
      deps: [forwardRef(() => AutoSizeVirtualScrollDirective)]
    }
  ]
})
export class AutoSizeVirtualScrollDirective implements OnChanges {
  /** The size of the items in the list (in pixels). */
  @Input()
  get itemSizes(): number[] {
    return this._itemSize;
  }
  set itemSizes(value: number[]) {
    this._itemSize = value.map(v => coerceNumberProperty(v));
  }
  _itemSize: number[] = [];

  /**
   * The minimum amount of buffer rendered beyond the viewport (in pixels).
   * If the amount of buffer dips below this number, more items will be rendered. Defaults to 100px.
   */
  @Input()
  get minBufferPx(): number {
    return this._minBufferPx;
  }
  set minBufferPx(value: NumberInput) {
    this._minBufferPx = coerceNumberProperty(value);
  }
  _minBufferPx = 100;

  /** The scroll strategy used by this directive. */
  _scrollStrategy = new AutoSizeVirtualScrollStrategy(this.itemSizes, this.minBufferPx);

  ngOnChanges() {
    this._scrollStrategy.updateItemAndBufferSize(this.itemSizes, this.minBufferPx);
  }
}

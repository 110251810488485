import {AgentLicense} from "./AgentLicense";
import {AgentOfficePhone} from "./Agent";
import {MediaWithType} from "./MediaWithType";

export enum AgentSignatureType {
  AGENT = "AGENT",
  AGENCY = "AGENCY"
}

export enum AgentSignatureFormat {
  DIGITAL = "DIGITAL",
  PRINT = "PRINT"
}

export enum AgentSignatureElement {
  DISPLAY_NAME = "DISPLAY_NAME",
  BRAND_NAME = "BRAND_NAME",
  BROKERAGE = "BROKERAGE",
  ADDRESS = "ADDRESS",
  EMAIL_ID = "EMAIL_ID",
  PHONE = "PHONE",
  OFFICE_PHONE = "OFFICE_PHONE",
  LICENSE = "LICENSE",
  WEBSITE = "WEBSITE"
}
export const AgentSignatureElementLabel: Record<AgentSignatureElement, string> = {
  [AgentSignatureElement.DISPLAY_NAME]: "Signature Display Name",
  [AgentSignatureElement.BRAND_NAME]: "Brand Name",
  [AgentSignatureElement.BROKERAGE]: "Brokerage Name",
  [AgentSignatureElement.ADDRESS]: "Address",
  [AgentSignatureElement.EMAIL_ID]: "Email",
  [AgentSignatureElement.PHONE]: "Mobile Phone",
  [AgentSignatureElement.OFFICE_PHONE]: "Office Phone",
  [AgentSignatureElement.LICENSE]: "License #",
  [AgentSignatureElement.WEBSITE]: "Website URL"
};
export type AgentSignatureElementToDataMapTypeInstance = {
  [AgentSignatureElement.DISPLAY_NAME]: string;
  [AgentSignatureElement.BRAND_NAME]: string;
  [AgentSignatureElement.BROKERAGE]: string;
  [AgentSignatureElement.ADDRESS]: string;
  [AgentSignatureElement.EMAIL_ID]: string;
  [AgentSignatureElement.PHONE]: string;
  [AgentSignatureElement.OFFICE_PHONE]: AgentOfficePhone;
  [AgentSignatureElement.LICENSE]: AgentLicense;
  [AgentSignatureElement.WEBSITE]: string;
};
export type AvailableSignatureElementTypes = keyof AgentSignatureElementToDataMapTypeInstance;

export type SignatureElementDataType<SignatureElementType extends AvailableSignatureElementTypes> =
  AgentSignatureElementToDataMapTypeInstance[SignatureElementType];
export interface AgentSignatureElementSettings<SignatureElementType extends AvailableSignatureElementTypes> {
  type: SignatureElementType;
  value: SignatureElementDataType<SignatureElementType>;
}

export interface AgentSignature {
  type: AgentSignatureType;
  format: AgentSignatureFormat;
  photo?: MediaWithType;
  signatureElementsSettings: AgentSignatureElementSettings<AvailableSignatureElementTypes>[];
}

import {Component, ElementRef, OnInit} from "@angular/core";
import {DashboardModuleButtonAction, DashboardModuleButtonType, DashboardModuleType} from "fello-model";
import {DashboardModuleDirective} from "../../directives/dashboard-module.directive";
import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AbstractDashboardService} from "../../services";

const CTABaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.CTA> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-cta-module",
  templateUrl: "./cta-module.component.html",
  styleUrls: ["./cta-module.component.scss"]
})
export class CtaModuleComponent extends CTABaseComponent implements OnInit {
  DashboardModuleButtonType = DashboardModuleButtonType;

  constructor(
    elementRef: ElementRef,
    private dashboardService: AbstractDashboardService,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  ngOnInit(): void {}

  performCTAAction(action: DashboardModuleButtonAction): void {
    this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
  }
}

export enum AgentPermissions {
  ACCESS_ALL_CONTACTS = "ACCESS_ALL_CONTACTS",
  ACCESS_UNASSIGNED_CONTACTS = "ACCESS_UNASSIGNED_CONTACTS",
  ADD_CONTACTS = "ADD_CONTACTS",
  IMPORT_CONTACTS = "IMPORT_CONTACTS",
  EXPORT_DATA = "EXPORT_DATA",
  MANAGE_MARKETING_SETTINGS = "MANAGE_MARKETING_SETTINGS",
  ACCESS_ACCOUNT_SETTINGS = "ACCESS_ACCOUNT_SETTINGS",
  MANAGE_USERS = "MANAGE_USERS",
  EMULATE_USERS = "EMULATE_USERS",
  ACCESS_BILLING = "ACCESS_BILLING"
}

export const AgentPermissionsLabel: Record<AgentPermissions, string> = {
  [AgentPermissions.ACCESS_ALL_CONTACTS]: "Access All Contact Records",
  [AgentPermissions.ACCESS_ACCOUNT_SETTINGS]: "Configure Account Settings",
  [AgentPermissions.ACCESS_UNASSIGNED_CONTACTS]: "Access to Unassigned Contacts",
  [AgentPermissions.ADD_CONTACTS]: "Add Contact Records",
  [AgentPermissions.IMPORT_CONTACTS]: "Import Contact Records",
  [AgentPermissions.EXPORT_DATA]: "Export Contact Records",
  [AgentPermissions.EMULATE_USERS]: "Emulate Users",
  [AgentPermissions.MANAGE_MARKETING_SETTINGS]: "Configure Marketing Settings",
  [AgentPermissions.MANAGE_USERS]: "Configure Users",
  [AgentPermissions.ACCESS_BILLING]: "Configure Billing Settings"
};

export const PERMISSIONS_TOOL_TIP_MSG: Partial<Record<AgentPermissions, string>> = {
  [AgentPermissions.ACCESS_ALL_CONTACTS]:
    "Users with this permission will be able to view and edit all contact records, even for contacts that are not assigned to them.",
  [AgentPermissions.EMULATE_USERS]:
    "Users with sufficient permission will be able to emulate other users, that is, they can access their Fello account and perform actions on behalf of them.",
  [AgentPermissions.MANAGE_USERS]:
    "Users with this permission will be able to add or remove users, edit user information, and configure roles.",
  [AgentPermissions.MANAGE_MARKETING_SETTINGS]:
    "Users with this permission will be able to configure marketing tools like Widgets, Branding, Contact Dashboard, Landing Pages, and Widgets.",
  [AgentPermissions.ADD_CONTACTS]: "Users with this permission will be able to add contact records manually in Fello.",
  [AgentPermissions.IMPORT_CONTACTS]: "Users with this permission will be able to import one or more contact records into Fello."
};

<div class="flex-box-center-between mb-4">
  <p class="fw-600 text-size-22">Send a Message</p>
  <button class="button-icon close ml-auto close-button" mat-dialog-close>
    <app-icon class="text-size-28">close</app-icon>
  </button>
</div>

<mat-dialog-content>
  <form #contactAgentForm="ngForm">
    <div class="flex-box flex-box-gap-2">
      <div class="form-field mb-4">
        <label for="form-first-name">First name</label>
        <input
          placeholder="First name"
          name="firstName"
          type="text"
          id="form-first-name"
          #firstNameField="ngModel"
          [maxlength]="49"
          required
          [(ngModel)]="data.firstName"
        />
        <div *ngIf="firstNameField.invalid && firstNameField.touched" class="text-size-12 text-color-red mt-1">
          <div *ngIf="firstNameField.errors!.required">Enter a First Name.</div>
          <div *ngIf="firstNameField.errors!.maxlength">First name cannot have more than 49 characters.</div>
        </div>
      </div>

      <div class="form-field mb-4">
        <label for="form-last-name">Last name</label>
        <input
          placeholder="Last name"
          name="lastName"
          type="text"
          id="form-last-name"
          #lastNameField="ngModel"
          [maxlength]="49"
          required
          [(ngModel)]="data.lastName"
        />
        <div *ngIf="lastNameField.invalid && lastNameField.touched" class="text-size-12 text-color-red mt-1">
          <div *ngIf="lastNameField.errors!.required">Enter a Last Name.</div>
          <div *ngIf="lastNameField.errors!.maxlength">Last name cannot have more than 49 characters.</div>
        </div>
      </div>
    </div>

    <div class="form-field mb-4">
      <label>Phone</label>
      <input
        type="text"
        [(ngModel)]="data.phone"
        #phone="ngModel"
        [pattern]="phoneNumberPattern"
        libFormatPhone
        placeholder="Phone"
        name="phone"
        required
      />
      <div *ngIf="phone.invalid && phone.touched" class="text-size-12 text-color-red mt-1">
        <div *ngIf="phone.errors!">Enter a valid phone.</div>
      </div>
    </div>
    <label>Message</label>
    <div class="form-field w-100">
      <textarea [(ngModel)]="data.message" name="message" placeholder="Message..." #message [maxlength]="2500"></textarea>
      <div class="textarea-count text-size-10 text-color-light">{{ message.textLength }}/2500</div>
    </div>
    <p class="text-size-12 text-color-light-2" *ngIf="settings$ | async; let settings">
      <ng-container *ngIf="settings.consentText">
        <span dynamicTooltipDirective class="link-underline" [innerHTML]="settings.consentText | safeHtml"></span>
      </ng-container>
      <ng-container *ngIf="!settings.consentText">
        By Proceeding, you agree to our
        <a target="_blank" [href]="settings.termsAndConditionsUrl ?? felloWebsites.TERMS_AND_CONDITIONS" class="text-underline"
          >Terms of Service</a
        >, and to our
        <a target="_blank" [href]="settings.privacyPolicyUrl ?? felloWebsites.PRIVACY_POLICY" class="text-underline">Privacy Policy</a>
        regarding the information relating to you. Msg/data rates may apply. This consent applies even if you are on a corporate, state or
        national Do Not Call list.
      </ng-container>
    </p>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="mt-3">
  <button class="w-100" (click)="contactAgentForm.control.markAllAsTouched(); contactAgentForm.valid && trackContactAgentEvent()">
    Send
  </button>
</mat-dialog-actions>

<ngx-spinner [name]="spinnerName" [fullScreen]="true"></ngx-spinner>

import {Observable} from "rxjs";
import {
  AgencyLender,
  AgentCashOfferWrapper,
  AgentContact,
  AgentContactProperty,
  AgentSignature,
  AVMMultiHistoryInfo,
  ChangelogEventType,
  CMAFromDashboardRequest,
  CompLite,
  ContactAgentFromDashboardRequest,
  DashboardHomeFactsUpdateRequest,
  DashboardModuleButtonAction,
  FelloConnectedAppType,
  FelloMultiAvmResponse,
  HomeEstimates,
  HomeValueFromDashboardRequest,
  LeadRequestFromDashboard,
  PropertyAndContact,
  PropertyHomeEquityResponse,
  SellingOptionsResponse,
  TrackTPModuleClickRequest
} from "fello-model";

export abstract class AbstractDashboardService {
  abstract propertyAndContact$: Observable<PropertyAndContact>;
  abstract property$: Observable<AgentContactProperty>;
  abstract isPropertyLoading: Observable<boolean>;
  abstract contact$: Observable<AgentContact>;
  abstract lender$: Observable<AgencyLender | undefined>;
  abstract offer$: Observable<AgentCashOfferWrapper>;
  abstract agentSignature$: Observable<AgentSignature | null>;
  abstract comps$: Observable<CompLite[]>;
  abstract homeEstimate$: Observable<HomeEstimates>;
  abstract felloMultiAvmResponse$: Observable<FelloMultiAvmResponse>;
  abstract sellingOptionsResponse$: Observable<SellingOptionsResponse>;
  abstract multiAvmHistory$: Observable<AVMMultiHistoryInfo>;
  abstract homeEquity$: Observable<PropertyHomeEquityResponse>;
  abstract isDummyDashboard$: Observable<boolean>;

  abstract refreshProperty(): void;

  abstract getMarketStatistics(): Observable<any>;

  abstract updateBuyingWithSelling(buyingArea: string, buyingPriceLow: number, buyingPriceHigh: number): Observable<void>;

  abstract updateHomeFacts(facts: DashboardHomeFactsUpdateRequest): Observable<void>;

  abstract trackEventAsync(eventType: ChangelogEventType, eventData?: any): void;

  abstract trackTPModuleClick(trackRequest: TrackTPModuleClickRequest): Observable<{_id: string}>;

  abstract submitContactAgentForm(
    eventType: ChangelogEventType,
    contactAgentFromDashboardRequest: ContactAgentFromDashboardRequest
  ): Observable<void>;

  abstract submitRequestCMAForm(cmaRequest: CMAFromDashboardRequest): Observable<void>;

  abstract performCTAAction(action: DashboardModuleButtonAction): Observable<{url: string}>;

  abstract submitCTAPopupLeadForm(
    action: DashboardModuleButtonAction,
    data: LeadRequestFromDashboard,
    connectedAppType?: FelloConnectedAppType
  ): Observable<void>;

  abstract addNewAddress(
    addAddressAction?: (
      propertyId: string,
      rawAddress: string,
      hvRequestFromDashboard: HomeValueFromDashboardRequest
    ) => Observable<AgentContactProperty>
  ): Observable<boolean>;

  abstract unClaimClaimedProperty(property: AgentContactProperty): Observable<boolean>;

  abstract unClaimUnClaimedProperty(property: AgentContactProperty): Observable<boolean>;
}

export enum PreBuiltAutomationCategory {
  LEAD_GENERATION = "LEAD_GENERATION",
  LEAD_NURTURING = "LEAD_NURTURING",
  FARMING = "FARMING"
}

export const PreBuiltAutomationCategoryOrder: Record<PreBuiltAutomationCategory, number> = {
  [PreBuiltAutomationCategory.LEAD_GENERATION]: 0,
  [PreBuiltAutomationCategory.LEAD_NURTURING]: 1,
  [PreBuiltAutomationCategory.FARMING]: 2
};

export const PreBuiltAutomationCategoryLabel: Record<PreBuiltAutomationCategory, string> = {
  [PreBuiltAutomationCategory.LEAD_GENERATION]: "Lead Generation",
  [PreBuiltAutomationCategory.LEAD_NURTURING]: "Lead Nurturing",
  [PreBuiltAutomationCategory.FARMING]: "Farming"
};

export const PreBuiltAutomationCategoryDesc: Record<PreBuiltAutomationCategory, string> = {
  [PreBuiltAutomationCategory.LEAD_GENERATION]:
    "Maximize your contact database to engage and convert new leads with precision. Our automations are designed to tap into your existing network, unlocking its full potential.",
  [PreBuiltAutomationCategory.LEAD_NURTURING]:
    "Advance your leads through the sales funnel with targeted automations. Focus on deepening relationships and guiding potential clients closer to a decision.",
  [PreBuiltAutomationCategory.FARMING]:
    "Expand your reach and uncover new prospects with our farming automations. Set your criteria and let the system find fresh opportunities to grow your database."
};

export const PreBuiltAutomationCategoryImage: Record<PreBuiltAutomationCategory, string> = {
  [PreBuiltAutomationCategory.LEAD_GENERATION]: "/assets/images/automations/lead-generation.svg",
  [PreBuiltAutomationCategory.LEAD_NURTURING]: "/assets/images/automations/lead-nurturing.svg",
  [PreBuiltAutomationCategory.FARMING]: "/assets/images/automations/farming.svg"
};

export const PreBuiltAutomationCategoryColorMapping: Record<string, string> = {
  [PreBuiltAutomationCategory.LEAD_GENERATION]: "green-light",
  [PreBuiltAutomationCategory.LEAD_NURTURING]: "orange-1",
  [PreBuiltAutomationCategory.FARMING]: "blue-light"
};

import {AddressComponent} from "../../address";
import {ChangelogEvent, ChangelogEventType} from "../../deal";
import {AgentContactEnrollmentStatus, CampaignType, ContactEngageActivityType} from "../Campaign";
import {FelloTag} from "../../common/FelloTag";
import {FelloVisitorVariables, HomeFacts} from "../../dashboard";
import {FelloConnectOrigin} from "../../enum";
import {AgentLeadType} from "../AgentLeadType";
import {AgentContactPropertyProfileData} from "./AgentContactPropertyProfileData";
import {RealSellerEvent} from "../real-seller";
import {AutomationMeta} from "../../automation/AutomationMeta";

export enum AgentContactStatus {
  LEAD = "LEAD",
  OPPORTUNITY = "OPPORTUNITY",
  OFFER_OR_LISTING_REQUESTED = "OFFER_OR_LISTING_REQUESTED",
  CUSTOMER = "CUSTOMER",
  CLOSED_WON = "CLOSED_WON",
  CLOSED_LOST = "CLOSED_LOST"
}

export const AgentContactStatusLabel: Record<AgentContactStatus, string> = {
  [AgentContactStatus.LEAD]: "Lead",
  [AgentContactStatus.OPPORTUNITY]: "Opportunity",
  [AgentContactStatus.OFFER_OR_LISTING_REQUESTED]: "Offer or Listing Requested",
  [AgentContactStatus.CUSTOMER]: "Customer",
  [AgentContactStatus.CLOSED_WON]: "Won",
  [AgentContactStatus.CLOSED_LOST]: "Lost"
};

export enum AgentContactEmailStatus {
  VALID = "VALID",
  INVALID = "INVALID",
  UNKNOWN = "UNKNOWN",
  UNSUBSCRIBED = "UNSUBSCRIBED"
}

export const AgentContactEmailStatusColor: Record<AgentContactEmailStatus, string> = {
  [AgentContactEmailStatus.VALID]: "green",
  [AgentContactEmailStatus.INVALID]: "red",
  [AgentContactEmailStatus.UNSUBSCRIBED]: "red",
  [AgentContactEmailStatus.UNKNOWN]: "slate-3"
};
export const AgentContactEmailStatusLabel: Record<AgentContactEmailStatus, string> = {
  [AgentContactEmailStatus.VALID]: "Valid Email Address",
  [AgentContactEmailStatus.INVALID]: "Invalid Email Address",
  [AgentContactEmailStatus.UNKNOWN]: "Verification Pending",
  [AgentContactEmailStatus.UNSUBSCRIBED]: "This email address has unsubscribed from communication. No Engage emails will be sent."
};

export enum AgentContactPropertyOrigin {
  FC = "FC",
  WEB = "WEB",
  ENGAGE = "EN",
  CRMX = "CRMX",
  T = "T",
  WIDGET = "WGT",
  ENRICH = "ENR",
  REFERRAL = "REFERRAL",
  LP = "LP",
  PURL = "PURL"
}

export enum ClaimStatus {
  NOT_CLAIMED = "NOT_CLAIMED",
  CLAIMED = "CLAIMED"
}

export interface AgentContactPropertyReferralInfo {
  isReferred: boolean;
  updatedBy: string;
  note?: string;
  updatedAt: string;
}

export interface AgentContactProperty {
  propertyId: string;
  clipId?: string;
  attomId?: string;
  quantId?: string;
  address: string;
  addressComponents: AddressComponent;
  origin: AgentContactPropertyOrigin;
  agentDealId?: string;
  homeFacts: HomeFacts;
  vars: FelloVisitorVariables;
  claimStatus: ClaimStatus;
  profile?: AgentContactPropertyProfileData;
  createdAt: string;
  lastRealSellerEvent?: RealSellerEvent;
  favouriteBy?: string[];
  referralInfo?: AgentContactPropertyReferralInfo;
  lastPostcardScanDate?: string;
  postcardScanCount?: number;
  dedupeInfo?: {
    isDup: boolean;
    dupCount: number;
    updatedAt: Date;
  };
}

export interface AgentContactCampaignLastActivity {
  type: ChangelogEventType;
  at: string;
}

export interface AgentContactEventTypeLastActivity {
  at: Date;
  count: number;
}

export interface AgentContactCampaignMeta {
  lastExecuted?: string;
  scheduledDate: string;
}

export enum AgentContactCampaignExclusion {
  agent_unenroled = "agent_unenroled",
  invalid_email = "invalid_email",
  mail_bounced = "mail_bounced",
  feature_disabled = "feature_disabled",
  user_unsub = "user_unsub",
  agency_paused = "agency_paused",
  agent_paused = "agent_paused",
  agency_dedupe = "agency_dedupe",
  account_level_disabled = "account_level_disabled",
  agent_engage_not_setup = "agent_engage_not_setup",
  feature_contact_limit_exceeded = "feature_contact_limit_exceeded",
  non_marketing = "non_marketing"
}

export interface AgentContactCampaignInfo extends Record<CampaignType, AgentContactCampaignMeta | undefined> {
  lastActivity?: AgentContactCampaignLastActivity;
  events?: Record<ChangelogEventType, AgentContactEventTypeLastActivity>;
}

export interface AgentContactCampaignEnrollmentInfo {
  enrolled: boolean;
  systemExcluded?: boolean;
  lastChangedAt: string;
  enrollmentStatus: AgentContactEnrollmentStatus;
  exclusions: AgentContactCampaignExclusion[];
}

export interface AgentContactEnrichmentInfo {
  enriched?: boolean;
  addressCaptureEnrichedAt?: Date;
}

export type AgentContactOrigin = FelloConnectOrigin;
export const AgentContactOrigin = FelloConnectOrigin;

export interface AgentContactOriginMeta {
  crmName?: string;
  url?: string;
}

interface AgentContactEmailValidation {
  isValid: boolean;
  validationDate: string;
}

interface AgentLatestLeadInfo {
  type: AgentLeadType;
  origin: FelloConnectOrigin;
  createdAt: Date;
}

interface AgentLeadInfo {
  count: number;
  latestLeadInfo?: AgentLatestLeadInfo;
}

export interface ContactEmail {
  emailId: string;
}
export interface AgentContactEmailUserUnsubscribed {
  isUnsub: boolean;
}

export interface AgentContactBase {
  _id: string;
  agencyId: string;
  ownerId: string; // Agent to which this contact is assigned or agent who is the owner of this contact
  emailId?: string;
  emails?: ContactEmail[];
  phone?: string;
  fullName: string;
  status: AgentContactStatus;
  createdAt: string;
  campaignInfo?: AgentContactCampaignInfo;
  campaignEnrollmentInfo: AgentContactCampaignEnrollmentInfo;
  enrichmentInfo?: AgentContactEnrichmentInfo;
  lastEngagement?: ChangelogEvent;
  tags?: FelloTag[];
  emailValidation?: AgentContactEmailValidation;
  origin?: AgentContactOrigin;
  originMeta?: AgentContactOriginMeta;
  crmDetails?: CRMDetails;
  agentLeadInfo?: AgentLeadInfo;
  isNonMarketing: boolean;
  numProperties: number;
  lastPostcardScanDate?: string;
  postcardScanCount?: number;
  automationMeta?: AutomationMeta[];
  emailUnsubscribed?: AgentContactEmailUserUnsubscribed;
}

export interface CRMDetails {
  name?: string;
  url?: string;
  source?: string;
  stage?: string;
  agentId?: string;
  agentName?: string;
  agentEmail?: string;
  createdDate?: string;
}

export interface AgentContact extends AgentContactBase {
  properties: AgentContactProperty[];
}

export interface AgentContactPropertyWithContact extends AgentContactProperty {
  _id: string; // propertyId
  contact: AgentContactBase;
  createdAt: string;
  updatedAt?: string;
}

export interface ContactProperty extends Omit<AgentContact, "properties"> {
  property?: AgentContactProperty;
}

export interface AgentContactWithEngageEnrollmentInfo extends AgentContact {
  enrollmentStatus: AgentContactEnrollmentStatus;
  campaignActivityType?: ContactEngageActivityType;
}

export interface AgentContactEnrollmentStatusWiseCount {
  [AgentContactEnrollmentStatus.ENROLLED]: number;
  [AgentContactEnrollmentStatus.UNENROLLED]: number;
  [AgentContactEnrollmentStatus.UNSUB]: number;
}

export interface PropertyDashboardUrlResponse {
  url: string;
}

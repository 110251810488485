<ngx-skeleton-loader *ngIf="isLoading; else isLoaded" count="1" [theme]="{  height: '90px', width: '100%' }"></ngx-skeleton-loader>

<ng-template #isLoaded>
  <ng-container *ngIf="(agentSignature$ | async); let agentSignature">
    <div class="db-card">
      <div class="db-card-header">
        <div>
          <p class="db-card-title fw-600 text-size-20">{{ moduleSetting.heading }}</p>
          <p class="text-size-14 fw-500 text-color-light-2 mt-1">{{ moduleSetting.subheading }}</p>
        </div>
      </div>
      <div class="db-card-body m-0">
        <ng-container>
          <div class="flex-box-lg-center-between mt-3">
            <lib-agent-signature [agentSignature]="agentSignature"></lib-agent-signature>
            <div class="mt-auto">
              <a
                class="button button-mid mt-mobile-16"
                [ngClass]="{'button-primary': moduleSetting.buttonType === DashboardModuleButtonType.SECONDARY}"
                (click)="openContactAgent()"
                >{{ moduleSetting.buttonLabel }}</a
              >
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

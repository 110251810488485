import {Component, ElementRef, OnInit, ViewContainerRef} from "@angular/core";
import {ChangelogEventType, DashboardModuleType, HomeEstimateConfidence} from "fello-model";
import {map, shareReplay, takeUntil, tap} from "rxjs/operators";
import {ImproveHomeEstimateComponent} from "../dialogs";
import {MatDialog} from "@angular/material/dialog";
import {combineLatest, Observable} from "rxjs";
import {mixinDestroyable} from "../../../../lib/mixins/";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives/dashboard-module.directive";
import {AbstractDashboardService, AbstractDashboardSettingsService} from "../../services";

@Component({
  selector: "lib-home-value-with-steps",
  templateUrl: "./home-value-with-steps.component.html",
  styleUrls: ["./home-value-with-steps.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: DashboardModuleDirectiveInputs
})
export class HomeValueWithStepsComponent
  extends mixinDestroyable(class extends DashboardModuleDirective<DashboardModuleType.HOME_ESTIMATE> {})
  implements OnInit
{
  isLoading$: Observable<boolean> = combineLatest([this.dashboardService.isPropertyLoading]).pipe(
    map(([dashboardLoading]) => {
      return dashboardLoading;
    })
  );
  property$ = this.dashboardService.property$;
  homeValueEstimate$ = this.dashboardService.homeEstimate$.pipe(shareReplay(1));
  HomeEstimateConfidence = HomeEstimateConfidence;
  agentSignature$ = this.dashboardService.agentSignature$;
  settings$ = this.dashboardSettingsService.settings$;

  constructor(
    protected dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    private dashboardSettingsService: AbstractDashboardSettingsService,
    elementRef: ElementRef
  ) {
    super(elementRef);
  }

  ngOnInit() {
    this.homeValueEstimate$
      .pipe(
        takeUntil(this.isDestroyed),
        tap(() => this.dashboardService.trackEventAsync(ChangelogEventType.SELLER_VIEWED_HOME_VALUE))
      )
      .subscribe();
  }

  scrollToBottom() {
    setTimeout(() => {
      const el = document.getElementById("estimateTerms");
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center", inline: "end"});
      }
    }, 100);
  }

  openImproveEstimate(): void {
    this.dialog.open(ImproveHomeEstimateComponent, {
      viewContainerRef: this.viewContainerRef,
      panelClass: ["mobile-bottom-sheet"]
    });
  }
}

<ng-container *ngIf="currentStep === ManageAddressSteps.Address_List">
  <ng-container *ngTemplateOutlet="listAddresses"></ng-container>
</ng-container>
<ng-container *ngIf="currentStep === ManageAddressSteps.Confirm_Contact_Details">
  <ng-container *ngTemplateOutlet="claimAddressContactDetails"></ng-container>
</ng-container>

<ng-template #listAddresses>
  <div class="flex-box-center-between mb-2">
    <h2 mat-dialog-title class="m-0 text-size-22 fw-700">Manage Your Homes</h2>
    <button class="button-icon close ml-auto" mat-dialog-close>
      <app-icon class="text-size-28">close</app-icon>
    </button>
  </div>

  <mat-dialog-content class="visible-scrollbar">
    <p class="text-size-16 fw-400 mb-4">
      You can now add and remove homes. Select any home to view the home value dashboard and request an instant offer.
    </p>
    <ng-container *ngIf="availableProperties$ | async; let availableProperties; else: loading">
      <div class="property-option" *ngFor="let property of availableProperties">
        <!--        <img [src]="img" alt="" *ngIf="(property.homeFacts?.img ?? [])[0]; let img; else streetViewImage" />-->
        <!--        <ng-template #streetViewImage>-->
        <ng-container *ngIf="property.propertyId | propertyExternalImg; let image; else: noImage">
          <img [src]="image" alt="" *ngIf="image" libFallbackImage="/assets/images/svg/no_property_image.svg" />
        </ng-container>
        <!--        </ng-template>-->
        <ng-template #noImage>
          <img src="/assets/images/svg/no_property_image_sq.svg" alt="house" />
        </ng-template>
        <div>
          <p class="fw-500 text-size-18">{{ property.addressComponents.doorNumber }} {{ property.addressComponents.street }}</p>
          <p class="fw-500 text-size-14 mt-1 flex-box-center flex-box-gap-1">
            {{ property.addressComponents.city }} , {{ property.addressComponents.state }} {{ property.addressComponents.zip }}
            <span class="text-color-green" libTooltip="Claimed" *ngIf="property.claimStatus === ClaimStatus.CLAIMED"
              ><lib-icon>checkmark</lib-icon></span
            >
          </p>
          <button
            class="link text-size-14 fw-400 mt-2"
            *ngIf="property.claimStatus === ClaimStatus.CLAIMED"
            (click)="unClaimClaimedProperty(property)"
          >
            Unclaim
          </button>
          <button
            class="link text-size-14 fw-400 mt-2"
            *ngIf="property.claimStatus === ClaimStatus.NOT_CLAIMED"
            (click)="unClaimUnClaimedProperty(property)"
          >
            Not my home
          </button>
        </div>
        <div class="ml-auto">
          <button
            (click)="goToDashboard(property.propertyId)"
            class="button-small button-primary"
            *ngIf="property.claimStatus === ClaimStatus.CLAIMED"
          >
            View Dashboard
          </button>
          <button
            (click)="selectedPropertyId = property.propertyId; currentStep = ManageAddressSteps.Confirm_Contact_Details"
            class="button-small"
            *ngIf="property.claimStatus === ClaimStatus.NOT_CLAIMED"
          >
            Claim
          </button>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="contact$ | async; let contact">
    <div
      [libTooltip]="disabledAddHomeToolTip"
      [showToolTip]="contact.properties.length >= 10"
      [tooltipClass]="'full-width'"
      class="ml-auto mb-w-100"
    >
      <button [disabled]="contact.properties.length >= 10" class="button-primary" (click)="addNewAddress()">Add new home</button>
    </div>
  </mat-dialog-actions>

  <ng-template #loading>
    <ngx-skeleton-loader count="3" [theme]="{height: '115px', width: '100%'}"></ngx-skeleton-loader>
  </ng-template>
</ng-template>

<ng-template #claimAddressContactDetails>
  <p class="text-size-22 fw-700 mb-2">One last step, let’s verify owner information.</p>
  <mat-dialog-content class="visible-scrollbar">
    <p class="text-size-16 fw-400 mb-2 mb-4">
      By confirming your details, you'll gain access to your personalized home dashboard, which includes an AI-based home value estimate and
      other valuable information to help you make informed decisions about your home
    </p>

    <form #contactDetailForm="ngForm" class="flex-box-direction-column flex-box-gap-3">
      <div class="flex-box flex-box-gap-2">
        <div class="form-field">
          <label class="mb-1 d-block text-size-14"
            >First Name <span class="fw-400" *ngIf="!hvFormSettings?.firstNameMandatory">(Optional)</span></label
          >
          <input
            type="text"
            placeholder="First Name"
            [(ngModel)]="homeValueFromDashboardRequest.firstName"
            [minlength]="2"
            [maxLength]="64"
            name="firstName"
            [required]="hvFormSettings?.firstNameMandatory ?? false"
            #firstNameField="ngModel"
          />
          <div *ngIf="firstNameField.invalid && firstNameField.touched" class="error-field">
            <div *ngIf="firstNameField.errors!">Enter a valid first name.</div>
          </div>
        </div>
        <div class="form-field">
          <label class="d-block text-size-14 mb-1"
            >Last Name <span class="fw-400" *ngIf="!hvFormSettings?.lastNameMandatory">(Optional)</span></label
          >
          <input
            type="text"
            placeholder="Last Name"
            [(ngModel)]="homeValueFromDashboardRequest.lastName"
            [maxLength]="64"
            [minlength]="1"
            [required]="hvFormSettings?.lastNameMandatory ?? false"
            #lastNameInput="ngModel"
            name="lastName"
          />
          <div *ngIf="lastNameInput.invalid && lastNameInput.touched" class="error-field">
            <div *ngIf="lastNameInput.errors!">Enter a valid last name.</div>
          </div>
        </div>
      </div>
      <div class="flex-box flex-box-gap-2">
        <div class="form-field">
          <label class="mb-1 d-block text-size-14">Email</label>
          <input
            type="text"
            placeholder="Email"
            name="email"
            class="disabled-grey"
            disabled
            [value]="homeValueFromDashboardRequest.emailId"
          />
        </div>
        <div class="form-field">
          <label class="d-block text-size-14 mb-1">
            Phone <span class="fw-400" *ngIf="!hvFormSettings?.phoneMandatory">(Optional)</span></label
          >
          <input
            type="tel"
            placeholder="Phone number"
            name="phone"
            libFormatPhone
            [required]="hvFormSettings?.phoneMandatory ?? false"
            [pattern]="phoneNumberPattern"
            [(ngModel)]="homeValueFromDashboardRequest.phone"
            #phone="ngModel"
          />
          <div *ngIf="phone.invalid && phone.touched" class="error-field">
            <div *ngIf="phone.errors!">Enter a valid phone.</div>
          </div>
        </div>
      </div>
      <div class="flex-box-direction-row" *ngIf="hvFormSettings?.includeSellingTimeline">
        <div class="form-field w-100 mb-4">
          <label>Are you considering selling your home? If yes, what is your potential timeframe? </label>
          <div class="form-field flex-box-center flex-box-gap-4 flex-wrap w-100">
            <div class="flex-box-center">
              <input
                type="radio"
                id="option0"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.ASAP"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option0">
                {{ SaleTimeline.ASAP | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option1"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.LT3M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option1">
                {{ SaleTimeline.LT3M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option2"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.B3TO6M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option2">
                {{ SaleTimeline.B3TO6M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option3"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.B6TO12M"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option3">
                {{ SaleTimeline.B6TO12M | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}
              </label>
            </div>
            <div class="flex-box-center">
              <input
                type="radio"
                id="option4"
                class="mr-2 w-auto"
                name="home-buy"
                [value]="SaleTimeline.NA"
                [(ngModel)]="homeValueFromDashboardRequest.saleTimeline"
                required
                #saleTimeline="ngModel"
              />
              <label class="text-size-14 fw-500 m-0 whitespace-nowrap c-pointer" for="option4">
                {{ SaleTimeline.NA | enumToLabel: "FELLO_SALE_TIMELINE_SHORT" }}</label
              >
            </div>
          </div>
          <div *ngIf="saleTimeline.control.touched && saleTimeline.errors?.required" class="text-size-12 text-color-red mt-1">
            <div>Please select an option.</div>
          </div>
        </div>
      </div>
      <p class="text-size-12 text-color-light-2" *ngIf="settings$ | async; let settings">
        <ng-container *ngIf="settings.consentText">
          <span dynamicTooltipDirective class="link-underline" [innerHTML]="settings.consentText | safeHtml"></span>
        </ng-container>
        <ng-container *ngIf="!settings.consentText">
          By Proceeding, you agree to our
          <a target="_blank" [href]="settings.termsAndConditionsUrl ?? felloWebsites.TERMS_AND_CONDITIONS" class="text-underline"
            >Terms of Service</a
          >, and to our
          <a target="_blank" [href]="settings.privacyPolicyUrl ?? felloWebsites.PRIVACY_POLICY" class="text-underline">Privacy Policy</a>
          regarding the information relating to you. Msg/data rates may apply. This consent applies even if you are on a corporate, state or
          national Do Not Call list.
        </ng-container>
      </p>
    </form>
    <ngx-spinner [showSpinner]="isLoading" [fullScreen]="true"></ngx-spinner>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button (click)="currentStep = ManageAddressSteps.Address_List" class="button-primary">Go Back</button>
    <button
      (click)="contactDetailForm.valid ? claimAddress() : contactDetailForm.control.markAllAsTouched()"
      [disabled]="isLoading"
      class="ml-auto"
    >
      Continue
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #disabledAddHomeToolTip>
  You have reached the maximum limit of 10 addresses.
  <br />
  Please delete an existing address to add a new one
</ng-template>

import {Component, ElementRef, OnInit, ViewContainerRef} from "@angular/core";
import {mixinDestroyable, mixinSpinnerAndToast} from "../../../../lib";
import {DashboardModuleDirective, DashboardModuleDirectiveInputs} from "../../directives";
import {AbstractDashboardService} from "../../services";
import {DashboardModuleButtonAction, DashboardModuleButtonActionType, DashboardModuleButtonType, DashboardModuleType} from "fello-model";
import {NgxSpinnerService} from "ngx-spinner";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {takeUntil, tap} from "rxjs/operators";
import {PopupLeadFormComponent} from "../dialogs";

const ThreeStepProcessBaseComponent = mixinDestroyable(
  mixinSpinnerAndToast(
    class extends DashboardModuleDirective<DashboardModuleType.THREE_STEP_PROCESS> {
      constructor(public spinnerService: NgxSpinnerService, public snackBar: MatSnackBar, elementRef: ElementRef) {
        super(elementRef);
      }
    }
  )
);

@Component({
  selector: "lib-three-step-process",
  templateUrl: "./three-step-process.component.html",
  styleUrls: ["./three-step-process.component.scss"],
  inputs: DashboardModuleDirectiveInputs
})
export class ThreeStepProcessComponent extends ThreeStepProcessBaseComponent implements OnInit {
  isLoading = true;
  DashboardModuleButtonType = DashboardModuleButtonType;

  constructor(
    private dashboardService: AbstractDashboardService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef,
    spinnerService: NgxSpinnerService,
    snackBar: MatSnackBar,
    elementRef: ElementRef
  ) {
    super(spinnerService, snackBar, elementRef);
  }

  ngOnInit() {
    this.dashboardService.isPropertyLoading
      .pipe(
        takeUntil(this.isDestroyed),
        tap(val => (this.isLoading = val))
      )
      .subscribe(
        () => {},
        () => (this.isLoading = false)
      );
  }

  performCTAAction(action: DashboardModuleButtonAction): void {
    if (action.actionType === DashboardModuleButtonActionType.POPUP_LEAD_FORM) {
      this.dialog.open(PopupLeadFormComponent, {
        maxWidth: 580,
        data: {
          action
        },
        viewContainerRef: this.viewContainerRef,
        panelClass: ["mobile-bottom-sheet"]
      });
    } else {
      this.withSpinner(this.dashboardService.performCTAAction(action)).subscribe();
    }
  }
}

<ngx-skeleton-loader *ngIf="isLoading; else loaded" count="1" [theme]="{height: '200px', width: '100%'}"></ngx-skeleton-loader>

<ng-template #loaded>
  <ng-container *ngIf="avmHis.length">
    <div class="flex-box-lg-direction-row flex-box-direction-column flex-box-gap-2">
      <p class="db-card-title text-size-16 flex-box-center flex-box-gap-1">{{ title }}</p>
      <div class="legends-c flex-box-center flex-box-gap-3 ml-auto">
        <div class="flex-box-center flex-box-gap-1 c-pointer" *ngFor="let his of avmHis; let index = index" (click)="getHomeValueInfo()">
          <span class="h-bar" [ngStyle]="{'background-color': colorScheme.domain[index]}"></span>
          <p class="text-size-12 fw-500">{{ his.name }}</p>
        </div>
      </div>
    </div>
    <div class="chart-c line-chart-defaults">
      <ngx-charts-line-chart
        [scheme]="colorScheme"
        [results]="avmHis"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [yAxisTickFormatting]="isDummyDashboard ? formatYaxisDummyValue : formatYaxisValue"
        [xAxisTicks]="xAxisTicks"
        [autoScale]="true"
        [yScaleMin]="yAxisMinValue"
        [yScaleMax]="yAxisMaxValue"
        [showGridLines]="true"
        class="d-block mt-3 w-100"
      >
        <ng-template #tooltipTemplate let-model="model">
          <p class="m-auto text-size-14 pt-1 pb-1 text-left">{{ model?.name }}</p>
          <p class="m-auto text-size-14 pt-1 text-left">
            <ng-container *ngIf="!model?.data?.isOverride"> {{ model?.series }} -</ng-container>
            <ng-container *ngIf="model?.data?.isOverride"> Agent Updated Home Value -</ng-container>
            {{ model?.value | formatPrice }}
          </p>
          <p class="m-auto text-size-10 text-left pb-1">
            Confidence - <span class="text-capitalize">{{ model?.data?.confidence | enumToLabel: "AVM_CONFIDENCE" }}</span>
          </p>
        </ng-template>
        <ng-template #seriesTooltipTemplate let-model="model">
          <p class="m-auto text-size-14 pt-1 pb-1 text-left">{{ model?.[0].name }}</p>
          <ng-container *ngIf="model[0]?.data?.isOverride && model[1]?.data?.isOverride">
            <div class="m-auto pb-1 pt-1" *ngIf="model[0]; let avmProvider">
              <p class="text-size-14 text-left">Agent Updated Home Value - {{ avmProvider?.value | formatPrice }}</p>
              <p class="text-size-10 text-left">Confidence - <span class="text-capitalize">High</span></p>
            </div>
          </ng-container>
          <ng-container *ngIf="!model[0]?.data?.isOverride || !model[1]?.data?.isOverride">
            <div class="m-auto pb-1 pt-1" *ngFor="let m of model">
              <p class="text-size-14 text-left">
                <ng-container *ngIf="!m?.data?.isOverride"> {{ m?.series }} -</ng-container>
                <ng-container *ngIf="m?.data?.isOverride"> Agent Updated Home Value -</ng-container>
                {{ m?.value | formatPrice }}
              </p>
              <p class="text-size-10 text-left">
                Confidence - <span class="text-capitalize">{{ m?.data?.confidence | enumToLabel: "AVM_CONFIDENCE" }}</span>
              </p>
            </div>
          </ng-container>
        </ng-template>
      </ngx-charts-line-chart>
    </div>
  </ng-container>
</ng-template>

export enum ChangelogEventType {
  // Deal ***************
  DEAL_RECEIVED = "DEAL_RECEIVED",
  PROCESSING_TRIGGERED = "PROCESSING_TRIGGERED",
  COMP_DESELECTED_FOR_SELLER_VISIBILITY = "COMP_DESELECTED_FOR_SELLER_VISIBILITY",
  COMP_SELECTED_FOR_SELLER_VISIBILITY = "COMP_SELECTED_FOR_SELLER_VISIBILITY",
  COMP_CONSIDERED_FOR_ALGO = "COMP_CONSIDERED_FOR_ALGO",
  COMP_NOT_CONSIDERED_FOR_ALGO = "COMP_NOT_CONSIDERED_FOR_ALGO",
  AVM_UPDATE = "AVM_UPDATED",
  PROPERTY_CONDITION_ADJUSTED = "PROPERTY_CONDITION_ADJUSTED",
  COMP_RESET = "COMP_RESET",
  SOURCE_PROPERTY_REFRESH = "SOURCE_PROPERTY_REFRESH",
  MARKET_VALUE_ADJUSTED = "MARKET_VALUE_ADJUSTED",
  OFFER_SENT_FOR_REVIEW = "OFFER_SENT_FOR_REVIEW",
  PRELIM_OFFER_READY = "PRELIM_OFFER_READY",
  FINAL_OFFER_READY = "FINAL_OFFER_READY",
  OFFER_PUBLISHED = "OFFER_PUBLISHED",
  MARKED_VIDEO_WALKTHROUGH_COMPLETED = "MARKED_VIDEO_WALKTHROUGH_COMPLETED",
  OFFER_EXPIRED = "OFFER_EXPIRED",
  COMP_ZONE_UPDATED = "COMP_ZONE_UPDATED",
  WEIGHTS_UPDATED = "WEIGHTS_UPDATED",
  TOOK_ACCESS = "TOOK_ACCESS",
  RELEASED_ACCESS = "RELEASED_ACCESS",
  UPDATED_PROFIT_ANALYSIS = "UPDATED_PROFIT_ANALYSIS",
  UPDATED_FLASH_OFFER = "UPDATED_FLASH_OFFER",
  SOURCE_PROPERTY_OVERRIDDEN = "SOURCE_PROPERTY_OVERRIDDEN",
  WALKTHROUGH_NOTES_UPDATED = "WALKTHROUGH_NOTES_UPDATED",
  VIDEO_WALKTHROUGH_SCHEDULED = "VIDEO_WALKTHROUGH_SCHEDULED",
  SELLER_REPAIRS_SENT_FOR_REVIEW = "SELLER_REPAIRS_SENT_FOR_REVIEW",
  SELLER_REPAIRS_REPORT_READY = "SELLER_REPAIRS_REPORT_READY",
  SELLER_REPAIRS_PUBLISHED = "SELLER_REPAIRS_PUBLISHED",
  ADMIN_REQUESTED_OFFER_AFTER_EXPIRY = "ADMIN_REQUESTED_OFFER_AFTER_EXPIRY",
  CONSIDERED_FOR_CLOSED_LOST = "CONSIDERED_FOR_CLOSED_LOST",
  MARKED_CLOSED_LOST = "MARKED_CLOSED_LOST",
  CLOSED_LOST_REQUEST_DENIED = "CLOSED_LOST_REQUEST_DENIED",
  MARKED_AS_NOT_OFFERING = "MARKED_AS_NOT_OFFERING",
  VIRTUAL_WALKTHROUGH_LINK_UPDATED = "VIRTUAL_WALKTHROUGH_LINK_UPDATED",

  DEAL_SAVED_ANALYSIS = "DEAL_SAVED_ANALYSIS",
  DEAL_ARCHIVED = "DEAL_ARCHIVED",
  DEAL_UN_ARCHIVED = "DEAL_UN_ARCHIVED",
  DEAL_DELETED = "DEAL_DELETED",
  DEAL_CREATED_DUE_TO_MERGER = "DEAL_CREATED_DUE_TO_MERGER",
  DEAL_ARCHIVED_DUE_TO_MERGER = "DEAL_ARCHIVED_DUE_TO_MERGER",
  SELLING_STRATEGY_UPDATED = "SELLING_STRATEGY_UPDATED",
  LISTING_STRATEGY_UPDATED = "LISTING_STRATEGY_UPDATED",

  // ******* Seller Events ********
  SELLER_ACCEPTED_FINAL_OFFER = "SELLER_ACCEPTED_FINAL_OFFER",
  SELLER_REQUESTED_OFFER_AFTER_EXPIRY = "SELLER_REQUESTED_OFFER_AFTER_EXPIRY",
  SELLER_ACCEPTED_REPAIRS = "SELLER_ACCEPTED_REPAIRS",
  SELLER_VIEWED_OFFER = "SELLER_VIEWED_OFFER",

  SELLER_VIEWED_HOME_VALUE = "SELLER_VIEWED_HOME_VALUE",
  SELLER_CLICKED_CONTACT_AGENT = "SELLER_CLICKED_CONTACT_AGENT",
  SELLER_DISAGREE_WITH_HOME_VALUE = "SELLER_DISAGREE_WITH_HOME_VALUE",
  SELLER_UPDATED_HOME_DETAILS = "SELLER_UPDATED_HOME_DETAILS",
  SELLER_CLICKED_FOR_IO = "SELLER_CLICKED_FOR_IO",
  SELLER_REQUESTED_CMA = "SELLER_REQUESTED_CMA",

  HINSPECTION_SCHEDULED = "HINSPECTION_SCHEDULED",
  HINSPECTION_SENT_FOR_REVIEW = "HINSPECTION_SENT_FOR_REVIEW",
  CLOSING_WALKTHROUGH_SENT_FOR_REVIEW = "CLOSING_WALKTHROUGH_SENT_FOR_REVIEW",
  QA_WALKTHROUGH_SENT_FOR_REVIEW = "QA_WALKTHROUGH_SENT_FOR_REVIEW",
  RENOVATION_WALKTHROUGH_SENT_FOR_REVIEW = "RENOVATION_WALKTHROUGH_SENT_FOR_REVIEW",

  INSPECTOR_PUBLISHED_HINSPECTION_REPORT = "INSPECTOR_PUBLISHED_HINSPECTION_REPORT",
  INSPECTOR_PUBLISHED_CLOSING_WLAKTHROUGH_REPORT = "INSPECTOR_PUBLISHED_CLOSING_WLAKTHROUGH_REPORT",
  INSPECTOR_PUBLISHED_QA_WALKTHROUGH_REPORT = "INSPECTOR_PUBLISHED_QA_WALKTHROUGH_REPORT",
  INSPECTOR_PUBLISHED_RENOVATION_WALKTHROUGH_REPORT = "INSPECTOR_PUBLISHED_RENOVATION_WALKTHROUGH_REPORT",

  RENOVATION_WALKTHROUGH_SKIPPED = "RENOVATION_WALKTHROUGH_SKIPPED",

  ACQ_POS_REPORT_UPLOADED = "ACQ_POS_REPORT_UPLOADED",
  ACQ_POS_PUBLISHED = "ACQ_POS_PUBLISHED",
  DEAL_STAGE_TRANSITION = "DEAL_STAGE_TRANSITION",

  LISTING_PRESENTATION_SAVED = "LISTING_PRESENTATION_SAVED",
  LISTING_PRESENTATION_PUBLISHED = "LISTING_PRESENTATION_PUBLISHED",
  COMP_SELECTED_FOR_LISTING_PRESENTATION = "COMP_SELECTED_FOR_LISTING_PRESENTATION",
  COMP_UNSELECTED_FOR_LISTING_PRESENTATION = "COMP_UNSELECTED_FOR_LISTING_PRESENTATION",

  /* Renovation Related Events Start */
  BEGUN_ESTIMATE = "BEGUN_ESTIMATE",
  PULLBACK_SUBMITTED_ESTIMATE = "PULLBACK_SUBMITTED_ESTIMATE",
  ESTIMATES_SUBMITTED = "ESTIMATES_SUBMITTED",
  ESTIMATES_SENT_FOR_REWORK = "ESTIMATES_SENT_FOR_REWORK",
  ESTIMATES_APPROVED = "ESTIMATES_APPROVED",
  SOW_APPROVED = "SOW_APPROVED",
  INVOICE_PAID = "INVOICE_PAID",
  /* Renovation Related Events End */

  ADDED_DEAL_ASSIGNEE = "ADDED_DEAL_ASSIGNEE",
  REMOVED_DEAL_ASSIGNEE = "REMOVED_DEAL_ASSIGNEE",

  GATE_CODE_UPDATED = "GATE_CODE_UPDATED",
  LOCK_CODE_UPDATED = "LOCK_CODE_UPDATED",
  ADDRESS_UPDATED = "ADDRESS_UPDATED",
  UPDATED_CLOSING_INFO = "UPDATED_CLOSING_INFO",

  UPDATED_REFERRAL_STATUS = "UPDATED_REFERRAL_STATUS",
  CONSIDERED_AS_REFERRAL = "CONSIDERED_AS_REFERRAL",
  REFERRED_AGENTS_TO_DEAL = "REFERRED_AGENTS_TO_DEAL",
  AGENT_REJECTED_REFERRAL = "AGENT_REJECTED_REFERRAL",
  AGENT_ACCEPTED_REFERRAL = "AGENT_ACCEPTED_REFERRAL",
  AGENT_ASSIGNED_TO_DEAL = "AGENT_ASSIGNED_TO_DEAL",
  DEAL_CONVERTED_TO_IO = "DEAL_CONVERTED_TO_IO",
  UPDATED_DEAL_SIGNERS = "UPDATED_DEAL_SIGNERS",
  REFERRED_INVESTOR_TO_DEAL = "REFERRED_INVESTOR_TO_DEAL",

  FC_DEAL_CREATED = "FC_DEAL_CREATED",

  /// Campaign
  CAMPAIGN_STARTED = "CAMPAIGN_STARTED",
  CAMPAIGN_AGENCY_ATTEMPT = "CAMPAIGN_AGENCY_ATTEMPT",
  CAMPAIGN_AGENT_ATTEMPT = "CAMPAIGN_AGENT_ATTEMPT",
  CAMPAIGN_CONTACT_ATTEMPT = "CAMPAIGN_CONTACT_ATTEMPT",
  CAMPAIGN_SKIPPED_CONTACT = "CAMPAIGN_SKIPPED_CONTACT",
  CAMPAIGN_MAIL_SENT = "CAMPAIGN_MAIL_SENT",
  CAMPAIGN_ABORTED = "CAMPAIGN_ABORTED",
  CAMPAIGN_COMPLETED = "CAMPAIGN_COMPLETED",

  CAMPAIGN_DEAL_CREATED = "CAMPAIGN_DEAL_CREATED",

  CAMPAIGN_ADDRESS_ADDED = "CAMPAIGN_ADDRESS_ADDED",

  MAIL_SENT = "MAIL_SENT",
  SMS_SENT = "SMS_SENT",
  POSTCARD_SENT = "POSTCARD_SENT",
  POSTCARD_SKIPPED = "POSTCARD_SKIPPED",
  POSTCARD_DELIVERED = "POSTCARD_DELIVERED",

  // Events
  MAIL_PROCESSED = "MAIL_PROCESSED",
  MAIL_UNKNOWN = "MAIL_UNKNOWN",
  MAIL_GROUP_RESUBSCRIBE = "MAIL_GROUP_RESUBSCRIBE",
  MAIL_GROUP_UNSUBSCRIBE = "MAIL_GROUP_UNSUBSCRIBE",
  MAIL_UNSUBSCRIBE = "MAIL_UNSUBSCRIBE",
  MAIL_SUBSCRIBE = "MAIL_SUBSCRIBE",
  MAIL_SPAM_REPORT = "MAIL_SPAM_REPORT",
  MAIL_CLICK = "MAIL_CLICK",
  MAIL_OPEN = "MAIL_OPEN",
  MAIL_BLOCKED = "MAIL_BLOCKED",
  MAIL_BOUNCE = "MAIL_BOUNCE",
  MAIL_DEFERRED = "MAIL_DEFERRED",
  MAIL_DELIVERED = "MAIL_DELIVERED",
  MAIL_DROPPED = "MAIL_DROPPED",

  /// Agent
  AGENT_MIGRATED = "AGENT_MIGRATED",
  AGENCY_MERGED = "AGENCY_MERGED",

  AGENT_HA_UPDATED = "AGENT_HA_UPDATED",
  AGENCY_HA_UPDATED = "AGENCY_HA_UPDATED",
  AGENT_SHARED_OFFER = "AGENT_SHARED_OFFER",

  // Agent Deal
  AGENT_DEAL_CREATED = "AGENT_DEAL_CREATED",
  AGENT_DEAL_STAGE_UPDATED = "AGENT_DEAL_STAGE_UPDATED",

  CONTACT_ENRICHED = "CONTACT_ENRICHED",

  PLAN_UPDATED = "PLAN_UPDATED",

  AGENT_INVITED_SELLER_FOR_DETAILS = "AGENT_INVITED_SELLER_FOR_DETAILS",
  DASHBOARD_CREATED = "DASHBOARD_CREATED",

  PLAN_UPGRADE_REQUEST_BY_AGENT = "PLAN_UPGRADE_REQUEST_BY_AGENT",

  // Offers
  CASHOFFER_ADDED = "CASHOFFER_ADDED",
  CASHOFFER_UPDATED = "CASHOFFER_UPDATED",
  CASHOFFER_EXPIRED = "CASHOFFER_EXPIRED",
  LISTING_OFFER_ADDED = "LISTING_OFFER_ADDED",
  LISTING_OFFER_UPDATED = "LISTING_OFFER_UPDATED",
  LISTING_OFFER_EXPIRED = "LISTING_OFFER_EXPIRED",

  OFFER_MARKED_ACTIVE_FOR_SELLER = "OFFER_MARKED_ACTIVE_FOR_SELLER",
  OFFER_MARKED_INACTIVE_FOR_SELLER = "OFFER_MARKED_INACTIVE_FOR_SELLER",

  AGENT_DEAL_STOP_NEW_ACQUIRE_OFFERS = "AGENT_DEAL_STOP_NEW_ACQUIRE_OFFERS",
  AGENT_DEAL_RESUME_NEW_ACQUIRE_OFFERS = "AGENT_DEAL_RESUME_NEW_ACQUIRE_OFFERS",

  AGENT_NOTIFIED_SELLER = "AGENT_NOTIFIED_SELLER",
  AGENT_NOTIFIED_SELLER_FOR_HOME_VALUE_UPDATE = "AGENT_NOTIFIED_SELLER_FOR_HOME_VALUE_UPDATE",
  AGENT_REQUESTED_ACQUIRE_OFFERS = "AGENT_REQUESTED_ACQUIRE_OFFERS",

  CONTACT_CREATED = "CONTACT_CREATED",
  CONTACT_UPDATED = "CONTACT_UPDATED",

  CONTACT_EMAIL_ADDED = "CONTACT_EMAIL_ADDED",
  CONTACT_EMAIL_REMOVED = "CONTACT_EMAIL_REMOVED",
  CONTACT_EMAIL_MARKED_PRIMARY = "CONTACT_EMAIL_MARKED_PRIMARY",
  CONTACT_EMAIL_MARKED_VALID = "CONTACT_EMAIL_MARKED_VALID",

  CONTACT_PROPERTY_ADDED = "CONTACT_PROPERTY_ADDED",
  CONTACT_PROPERTY_REMOVED = "CONTACT_PROPERTY_REMOVED",

  CONTACT_PROPERTY_MARK_REFERRED = "CONTACT_PROPERTY_MARK_REFERRED",
  CONTACT_PROPERTY_UNMARK_REFERRED = "CONTACT_PROPERTY_UNMARK_REFERRED",
  CONTACT_PROPERTY_UNCLAIMED = "CONTACT_PROPERTY_UNCLAIMED",
  CONTACT_PROPERTY_CLAIMED = "CONTACT_PROPERTY_CLAIMED",
  CONTACT_OWNER_UPDATED = "CONTACT_OWNER_UPDATED",
  CONTACT_MARKETING_STATUS_UPDATED = "CONTACT_MARKETING_STATUS_UPDATED",
  HOME_VALUE_REQUESTED = "HOME_VALUE_REQUESTED",
  SELLER_LEAD_REQUESTED = "SELLER_LEAD_REQUESTED",
  AGENCY_PAUSED_ENGAGE = "AGENCY_PAUSED_ENGAGE",
  SELLER_CO_SUBMITTED = "SELLER_CO_SUBMITTED",
  REFERRAL_LEAD_REFERRED = "REFERRAL_LEAD_REFERRED",
  HOME_VALUE_UPDATED = "HOME_VALUE_UPDATED",
  PROPERTY_FACTS_UPDATED_BY_AGENT = "PROPERTY_FACTS_UPDATED_BY_AGENT",
  CONTACT_TAG_MANUALLY_ADDED = "CONTACT_TAG_MANUALLY_ADDED",
  CONTACT_TAG_REMOVED = "CONTACT_TAG_REMOVED",
  AGENT_LEAD_CREATED = "AGENT_LEAD_CREATED",
  USER_DROPPED_OFF = "USER_DROPPED_OFF",

  // Postcard Events
  POSTCARD_SCANNED_PRIMARY = "POSTCARD_SCANNED_PRIMARY",
  POSTCARD_SCANNED_SECONDARY = "POSTCARD_SCANNED_SECONDARY",

  // Automation Events
  AUTOMATION_ENTITY_ENROLLED = "AUTOMATION_ENTITY_ENROLLED",
  AUTOMATION_ENTITY_COMPLETED = "AUTOMATION_ENTITY_COMPLETED",
  AUTOMATION_ENTITY_REENROLLED = "AUTOMATION_ENTITY_REENROLLED",
  AUTOMATION_ENTITY_UNENROLLED = "AUTOMATION_ENTITY_UNENROLLED",
  AUTOMATION_ACTION_EXECUTED = "AUTOMATION_ACTION_EXECUTED",
  AUTOMATION_EMAIL_SKIPPED = "AUTOMATION_EMAIL_SKIPPED",
  AUTOMATION_ACTION_SKIPPED = "AUTOMATION_ACTION_SKIPPED",

  TP_MODULE_BTN_CLICK = "TP_MODULE_BTN_CLICK",

  // Homeward Events
  HOMEWARD_PRELIM_OFFER_REQUESTED = "HOMEWARD_PRELIM_OFFER_REQUESTED",
  HOMEWARD_PRELIM_OFFER_FINALIZED = "HOMEWARD_PRELIM_OFFER_FINALIZED",
  HOMEWARD_PRELIM_OFFER_EXPIRED = "HOMEWARD_PRELIM_OFFER_EXPIRED"
}

export const ChangelogEventTypeToLabelMapping: Record<string, string> = {
  [ChangelogEventType.OFFER_PUBLISHED]: "Offer published",
  [ChangelogEventType.SELLER_REPAIRS_PUBLISHED]: "Seller repairs published",
  [ChangelogEventType.SELLER_ACCEPTED_FINAL_OFFER]: "Seller accepted final offer",
  [ChangelogEventType.SELLER_ACCEPTED_REPAIRS]: "Seller accepted repairs",
  [ChangelogEventType.SELLER_VIEWED_OFFER]: "Seller viewed offer",
  [ChangelogEventType.VIDEO_WALKTHROUGH_SCHEDULED]: "Video walkthrough scheduled",
  [ChangelogEventType.HINSPECTION_SCHEDULED]: "Home Inspection scheduled",

  [ChangelogEventType.MAIL_BLOCKED]: "Contact mail blocked",
  [ChangelogEventType.MAIL_CLICK]: "Contact clicked email",
  [ChangelogEventType.MAIL_BOUNCE]: "Contact mail bounced",
  [ChangelogEventType.MAIL_DEFERRED]: "Contact mail deferred",
  [ChangelogEventType.MAIL_DELIVERED]: "Engage email sent",
  [ChangelogEventType.MAIL_OPEN]: "Contact opened email",
  [ChangelogEventType.MAIL_UNSUBSCRIBE]: "Contact unsubscribed from engage",
  [ChangelogEventType.MAIL_PROCESSED]: "Contact mail processed",
  [ChangelogEventType.MAIL_SUBSCRIBE]: "Contact subscribed for engage",
  [ChangelogEventType.MAIL_SPAM_REPORT]: "Mail reported as spam",
  [ChangelogEventType.SELLER_VIEWED_HOME_VALUE]: "Contact viewed Home Value",
  [ChangelogEventType.ADDRESS_UPDATED]: "Updated address",
  [ChangelogEventType.SELLER_UPDATED_HOME_DETAILS]: "Contact edited property details from Home Value dashboard",
  [ChangelogEventType.SELLER_CLICKED_FOR_IO]: "Started Cash offer form",
  [ChangelogEventType.SELLER_CLICKED_CONTACT_AGENT]: "Submitted Callback request form",
  [ChangelogEventType.SELLER_DISAGREE_WITH_HOME_VALUE]: "Seller requests updated Home Value",
  [ChangelogEventType.AGENT_DEAL_CREATED]: "Submitted Cash offer form",
  [ChangelogEventType.SELLER_REQUESTED_CMA]: "Submitted CMA request form",

  [ChangelogEventType.CONTACT_ENRICHED]: "New property added via automation platform",

  [ChangelogEventType.CAMPAIGN_MAIL_SENT]: "Campaign mail sent",
  [ChangelogEventType.CAMPAIGN_SKIPPED_CONTACT]: "Campaign skipped contact",
  [ChangelogEventType.CAMPAIGN_CONTACT_ATTEMPT]: "Campaign contact skipped",
  [ChangelogEventType.CAMPAIGN_DEAL_CREATED]: "Deal created by campaign",
  [ChangelogEventType.CAMPAIGN_ADDRESS_ADDED]: "Added address by campaign",
  [ChangelogEventType.AGENT_DEAL_STAGE_UPDATED]: "Deal stage updated",
  [ChangelogEventType.AGENT_INVITED_SELLER_FOR_DETAILS]: "Invited contact to submit Cash offer form",
  [ChangelogEventType.DASHBOARD_CREATED]: "New Cash offer request",

  [ChangelogEventType.AGENT_REQUESTED_ACQUIRE_OFFERS]: "Requested offers from buyers",
  [ChangelogEventType.CASHOFFER_ADDED]: "Cash offer added for contact",
  [ChangelogEventType.CASHOFFER_EXPIRED]: "Buyer offer expired for contact",
  [ChangelogEventType.LISTING_OFFER_ADDED]: "Agent added traditional listing offer for contact",
  [ChangelogEventType.LISTING_OFFER_EXPIRED]: "Listing Offer Expired for contact",
  [ChangelogEventType.AGENT_DEAL_STOP_NEW_ACQUIRE_OFFERS]: "Marked no longer accepting offers",
  [ChangelogEventType.AGENT_DEAL_RESUME_NEW_ACQUIRE_OFFERS]: "Resumed getting offers from buyers",
  [ChangelogEventType.AGENT_NOTIFIED_SELLER]: "Agent notified seller",
  [ChangelogEventType.OFFER_MARKED_INACTIVE_FOR_SELLER]: "Agent marked offer inactive for contact",

  [ChangelogEventType.CONTACT_CREATED]: "Contact added",
  [ChangelogEventType.CONTACT_UPDATED]: "Contact details updated",

  [ChangelogEventType.CONTACT_EMAIL_ADDED]: "Contact email added",
  [ChangelogEventType.CONTACT_EMAIL_REMOVED]: "Contact email deleted",
  [ChangelogEventType.CONTACT_EMAIL_MARKED_PRIMARY]: "Contact email marked primary",
  [ChangelogEventType.CONTACT_EMAIL_MARKED_VALID]: "Contact email marked valid",

  [ChangelogEventType.CONTACT_PROPERTY_ADDED]: "New property added",
  [ChangelogEventType.CONTACT_PROPERTY_REMOVED]: "Property removed",
  [ChangelogEventType.CONTACT_PROPERTY_UNCLAIMED]: "Property Unclaimed",
  [ChangelogEventType.CONTACT_PROPERTY_CLAIMED]: "Property Claimed",

  [ChangelogEventType.CONTACT_PROPERTY_MARK_REFERRED]: "Property Referred",
  [ChangelogEventType.CONTACT_PROPERTY_UNMARK_REFERRED]: "Property Unmark Referred",

  [ChangelogEventType.HOME_VALUE_REQUESTED]: "Contact submitted Home Value form",
  [ChangelogEventType.SELLER_LEAD_REQUESTED]: "Submitted Seller Form",
  [ChangelogEventType.SELLER_CO_SUBMITTED]: "Submitted Seller Lead form",
  [ChangelogEventType.REFERRAL_LEAD_REFERRED]: "New seller lead referred from Fello",
  [ChangelogEventType.PROPERTY_FACTS_UPDATED_BY_AGENT]: "Agent edited property details from Connect",
  [ChangelogEventType.HOME_VALUE_UPDATED]: "Agent updated home estimate",
  [ChangelogEventType.AGENT_NOTIFIED_SELLER_FOR_HOME_VALUE_UPDATE]: "Home Value update notification sent via email",
  [ChangelogEventType.AGENT_LEAD_CREATED]: "New Lead created",
  [ChangelogEventType.USER_DROPPED_OFF]: "Drop Off",
  [ChangelogEventType.CONTACT_OWNER_UPDATED]: "Contact owner updated",
  [ChangelogEventType.CONTACT_MARKETING_STATUS_UPDATED]: "Record status changed",
  [ChangelogEventType.POSTCARD_SENT]: "Postcard Sent",
  [ChangelogEventType.POSTCARD_SCANNED_PRIMARY]: "Postcard Scans",
  [ChangelogEventType.POSTCARD_SCANNED_SECONDARY]: "Postcard Scans",
  [ChangelogEventType.TP_MODULE_BTN_CLICK]: "Module Button Clicked",
  [ChangelogEventType.CONTACT_TAG_REMOVED]: "Contact Tag Removed",
  [ChangelogEventType.CONTACT_TAG_MANUALLY_ADDED]: "Contact Tag Added",
  [ChangelogEventType.AUTOMATION_ENTITY_ENROLLED]: "Enrolled in Automation",
  [ChangelogEventType.AUTOMATION_ENTITY_REENROLLED]: "Re-enrolled in Automation",
  [ChangelogEventType.AUTOMATION_ENTITY_COMPLETED]: "Completed Automation",
  [ChangelogEventType.AUTOMATION_ENTITY_UNENROLLED]: "Unenrolled from Automation",
  [ChangelogEventType.AUTOMATION_ACTION_SKIPPED]: "Skipped action in Automation",
  [ChangelogEventType.HOMEWARD_PRELIM_OFFER_EXPIRED]: "Homeward prelim offer expired",
  [ChangelogEventType.HOMEWARD_PRELIM_OFFER_FINALIZED]: "Homeward prelim offer finalized",
  [ChangelogEventType.HOMEWARD_PRELIM_OFFER_REQUESTED]: "Homeward prelim offer requested"
};

export enum ChangelogEventGroup {
  ENGAGE = "ENGAGE",
  SELLER = "SELLER",
  OFFER = "OFFER",
  AGENT = "AGENT",
  ENRICH = "ENRICH",
  SYSTEM = "SYSTEM",
  HOMEWARD = "HOMEWARD"
}

//TODO generate this through mapping below and remove this
export const ChangelogEventGroupToChangelogsMapping: Record<ChangelogEventGroup, ChangelogEventType[]> = {
  [ChangelogEventGroup.ENGAGE]: [
    ChangelogEventType.MAIL_DELIVERED,
    ChangelogEventType.MAIL_OPEN,
    ChangelogEventType.MAIL_CLICK,
    ChangelogEventType.MAIL_UNSUBSCRIBE,
    ChangelogEventType.MAIL_SUBSCRIBE
  ],
  [ChangelogEventGroup.SELLER]: [
    ChangelogEventType.SELLER_VIEWED_HOME_VALUE,
    ChangelogEventType.SELLER_UPDATED_HOME_DETAILS,
    ChangelogEventType.SELLER_DISAGREE_WITH_HOME_VALUE,
    ChangelogEventType.CONTACT_PROPERTY_ADDED,
    ChangelogEventType.CONTACT_PROPERTY_REMOVED,
    ChangelogEventType.CONTACT_PROPERTY_MARK_REFERRED,
    ChangelogEventType.CONTACT_PROPERTY_UNMARK_REFERRED,
    ChangelogEventType.CONTACT_UPDATED,
    ChangelogEventType.CONTACT_EMAIL_ADDED,
    ChangelogEventType.CONTACT_EMAIL_REMOVED,
    ChangelogEventType.CONTACT_EMAIL_MARKED_PRIMARY,
    ChangelogEventType.PROPERTY_FACTS_UPDATED_BY_AGENT,
    ChangelogEventType.CONTACT_EMAIL_MARKED_VALID
  ],
  [ChangelogEventGroup.OFFER]: [
    ChangelogEventType.AGENT_INVITED_SELLER_FOR_DETAILS,
    ChangelogEventType.AGENT_DEAL_CREATED,
    ChangelogEventType.CASHOFFER_ADDED,
    ChangelogEventType.LISTING_OFFER_ADDED,
    ChangelogEventType.AGENT_REQUESTED_ACQUIRE_OFFERS,
    ChangelogEventType.AGENT_DEAL_RESUME_NEW_ACQUIRE_OFFERS,
    ChangelogEventType.AGENT_DEAL_STOP_NEW_ACQUIRE_OFFERS,
    ChangelogEventType.AGENT_NOTIFIED_SELLER,
    ChangelogEventType.CASHOFFER_EXPIRED,
    ChangelogEventType.LISTING_OFFER_EXPIRED,
    ChangelogEventType.OFFER_MARKED_INACTIVE_FOR_SELLER,
    ChangelogEventType.HOME_VALUE_REQUESTED,
    ChangelogEventType.SELLER_CLICKED_CONTACT_AGENT,
    ChangelogEventType.SELLER_REQUESTED_CMA,
    ChangelogEventType.SELLER_CO_SUBMITTED,
    ChangelogEventType.AGENT_LEAD_CREATED,
    ChangelogEventType.REFERRAL_LEAD_REFERRED,
    ChangelogEventType.AGENT_NOTIFIED_SELLER_FOR_HOME_VALUE_UPDATE
  ],
  [ChangelogEventGroup.AGENT]: [
    ChangelogEventType.HOME_VALUE_UPDATED,
    ChangelogEventType.CONTACT_OWNER_UPDATED,
    ChangelogEventType.CONTACT_TAG_MANUALLY_ADDED,
    ChangelogEventType.CONTACT_TAG_REMOVED
  ],
  [ChangelogEventGroup.ENRICH]: [],
  [ChangelogEventGroup.SYSTEM]: [
    ChangelogEventType.CONTACT_CREATED,
    ChangelogEventType.CONTACT_ENRICHED,
    ChangelogEventType.CONTACT_MARKETING_STATUS_UPDATED
  ],
  [ChangelogEventGroup.HOMEWARD]: [
    ChangelogEventType.HOMEWARD_PRELIM_OFFER_EXPIRED,
    ChangelogEventType.HOMEWARD_PRELIM_OFFER_FINALIZED,
    ChangelogEventType.HOMEWARD_PRELIM_OFFER_REQUESTED
  ]
};
export const ChangelogToChangelogEventGroupMapping: Partial<Record<string, ChangelogEventGroup>> = {
  [ChangelogEventType.MAIL_DELIVERED]: ChangelogEventGroup.ENGAGE,
  [ChangelogEventType.MAIL_OPEN]: ChangelogEventGroup.ENGAGE,
  [ChangelogEventType.MAIL_CLICK]: ChangelogEventGroup.ENGAGE,
  [ChangelogEventType.MAIL_UNSUBSCRIBE]: ChangelogEventGroup.ENGAGE,
  [ChangelogEventType.MAIL_SUBSCRIBE]: ChangelogEventGroup.ENGAGE,

  [ChangelogEventType.SELLER_VIEWED_HOME_VALUE]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.SELLER_UPDATED_HOME_DETAILS]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.SELLER_DISAGREE_WITH_HOME_VALUE]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_PROPERTY_ADDED]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_PROPERTY_REMOVED]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_PROPERTY_MARK_REFERRED]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_PROPERTY_UNMARK_REFERRED]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_EMAIL_ADDED]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_EMAIL_REMOVED]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_EMAIL_MARKED_PRIMARY]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.CONTACT_EMAIL_MARKED_VALID]: ChangelogEventGroup.SELLER,
  [ChangelogEventType.PROPERTY_FACTS_UPDATED_BY_AGENT]: ChangelogEventGroup.SELLER,

  [ChangelogEventType.AGENT_INVITED_SELLER_FOR_DETAILS]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.AGENT_DEAL_CREATED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.CASHOFFER_ADDED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.LISTING_OFFER_ADDED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.AGENT_REQUESTED_ACQUIRE_OFFERS]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.AGENT_DEAL_RESUME_NEW_ACQUIRE_OFFERS]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.AGENT_DEAL_STOP_NEW_ACQUIRE_OFFERS]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.AGENT_NOTIFIED_SELLER]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.HOME_VALUE_REQUESTED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.SELLER_CLICKED_CONTACT_AGENT]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.SELLER_REQUESTED_CMA]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.SELLER_CO_SUBMITTED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.REFERRAL_LEAD_REFERRED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.AGENT_NOTIFIED_SELLER_FOR_HOME_VALUE_UPDATE]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.CASHOFFER_EXPIRED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.LISTING_OFFER_EXPIRED]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.OFFER_MARKED_INACTIVE_FOR_SELLER]: ChangelogEventGroup.OFFER,
  [ChangelogEventType.AGENT_LEAD_CREATED]: ChangelogEventGroup.OFFER,

  [ChangelogEventType.CONTACT_ENRICHED]: ChangelogEventGroup.SYSTEM,
  [ChangelogEventType.CONTACT_CREATED]: ChangelogEventGroup.SYSTEM,
  [ChangelogEventType.CONTACT_MARKETING_STATUS_UPDATED]: ChangelogEventGroup.SYSTEM,

  [ChangelogEventType.HOME_VALUE_UPDATED]: ChangelogEventGroup.AGENT,
  [ChangelogEventType.CONTACT_OWNER_UPDATED]: ChangelogEventGroup.AGENT,
  [ChangelogEventType.CONTACT_TAG_MANUALLY_ADDED]: ChangelogEventGroup.AGENT,
  [ChangelogEventType.CONTACT_TAG_REMOVED]: ChangelogEventGroup.AGENT,

  [ChangelogEventType.HOMEWARD_PRELIM_OFFER_EXPIRED]: ChangelogEventGroup.HOMEWARD,
  [ChangelogEventType.HOMEWARD_PRELIM_OFFER_FINALIZED]: ChangelogEventGroup.HOMEWARD,
  [ChangelogEventType.HOMEWARD_PRELIM_OFFER_REQUESTED]: ChangelogEventGroup.HOMEWARD
};

import {Feature} from "./Feature";
import {NumberOrUnlimited} from "../../number";

export enum AgencyPlanType {
  NO_PLAN = "NO_PLAN",
  BETA = "BETA",
  PROFESSIONAL = "PROFESSIONAL",
  ENTERPRISE = "ENTERPRISE",
  BUSINESS = "BUSINESS",
  TEAM = "TEAM",
  LIVIAN = "LIVIAN"
}

export const AgencyPlanTypeLabel: Record<AgencyPlanType, string> = {
  [AgencyPlanType.NO_PLAN]: "No Plan",
  [AgencyPlanType.ENTERPRISE]: "Enterprise",
  [AgencyPlanType.BUSINESS]: "Business",
  [AgencyPlanType.BETA]: "Beta",
  [AgencyPlanType.PROFESSIONAL]: "Professional",
  [AgencyPlanType.TEAM]: "Team",
  [AgencyPlanType.LIVIAN]: "Livian"
};

export interface AgencyPlanUpgradeRequest {
  at: string;
  remarks: string;
  requestedByAgentId: string;
}

export interface AgencyPlan {
  planType: AgencyPlanType;
  startDate?: string;
  expireDate?: string;
  features: Feature[];
  allowedNumOfUsers: NumberOrUnlimited;
  allowedNumOfContacts: NumberOrUnlimited;
  allowedNumOfLandingPages: NumberOrUnlimited;
  allowedNumOfWidgets: NumberOrUnlimited;
  allowedNumOfBrands: NumberOrUnlimited;
  updatedAt: string;
  remarks?: string;
  upgradeRequest?: AgencyPlanUpgradeRequest;
}
